import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Byte` scalar type represents non-fractional whole numeric values. Byte can represent values between 0 and 255. */
  Byte: any;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: any;
  /** The built-in `Decimal` scalar type. */
  Decimal: any;
  UUID: any;
};

export type AcceptTermsAndConditionsPayload = {
  __typename?: 'AcceptTermsAndConditionsPayload';
  account?: Maybe<Account>;
};

export type Account = {
  __typename?: 'Account';
  aboutUs?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  branches: Array<Branch>;
  cancelSettings?: Maybe<CancelSettings>;
  customers: Array<Customer>;
  customersRequests: Array<CustomerRequest>;
  discriminator: Scalars['String'];
  email: Scalars['String'];
  employees: Array<Employee>;
  hrAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  permissions: Array<AccountPermission>;
  personioUser: Scalars['Boolean'];
  phone?: Maybe<Scalars['String']>;
  platformAccess: Scalars['Boolean'];
  readChats: Array<QuoteChat>;
  status: AccountStatus;
  termsAndConditionsAccepted: Scalars['Boolean'];
  termsAndConditionsUrl?: Maybe<Scalars['String']>;
  users: Array<User>;
  ventoWorkAccess: Scalars['Boolean'];
};

export type AccountFilterInput = {
  aboutUs?: InputMaybe<StringOperationFilterInput>;
  address?: InputMaybe<AddressFilterInput>;
  and?: InputMaybe<Array<AccountFilterInput>>;
  branches?: InputMaybe<ListFilterInputTypeOfBranchFilterInput>;
  cancelSettings?: InputMaybe<CancelSettingsFilterInput>;
  customers?: InputMaybe<ListFilterInputTypeOfCustomerFilterInput>;
  customersRequests?: InputMaybe<ListFilterInputTypeOfCustomerRequestFilterInput>;
  discriminator?: InputMaybe<StringOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  employees?: InputMaybe<ListFilterInputTypeOfEmployeeFilterInput>;
  hrAccess?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  logoUrl?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AccountFilterInput>>;
  permissions?: InputMaybe<ListFilterInputTypeOfAccountPermissionFilterInput>;
  personioUser?: InputMaybe<BooleanOperationFilterInput>;
  phone?: InputMaybe<StringOperationFilterInput>;
  platformAccess?: InputMaybe<BooleanOperationFilterInput>;
  readChats?: InputMaybe<ListFilterInputTypeOfQuoteChatFilterInput>;
  status?: InputMaybe<AccountStatusOperationFilterInput>;
  termsAndConditionsAccepted?: InputMaybe<BooleanOperationFilterInput>;
  termsAndConditionsUrl?: InputMaybe<StringOperationFilterInput>;
  users?: InputMaybe<ListFilterInputTypeOfUserFilterInput>;
  ventoWorkAccess?: InputMaybe<BooleanOperationFilterInput>;
};

export type AccountPermission = {
  __typename?: 'AccountPermission';
  account: Account;
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  id: Scalars['ID'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  scope: AccountPermissionScope;
  target: NotificationTarget;
  updatedBy?: Maybe<User>;
};

export type AccountPermissionFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  and?: InputMaybe<Array<AccountPermissionFilterInput>>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AccountPermissionFilterInput>>;
  scope?: InputMaybe<AccountPermissionScopeOperationFilterInput>;
  target?: InputMaybe<NotificationTargetOperationFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export enum AccountPermissionScope {
  CreateCustomers = 'CREATE_CUSTOMERS',
  CreateEvents = 'CREATE_EVENTS',
  CreateLocations = 'CREATE_LOCATIONS',
  CreateQuotes = 'CREATE_QUOTES',
  CustomerManagement = 'CUSTOMER_MANAGEMENT',
  DocumentManagement = 'DOCUMENT_MANAGEMENT',
  EmployeeManagement = 'EMPLOYEE_MANAGEMENT',
  EventManagement = 'EVENT_MANAGEMENT',
  LocationManagement = 'LOCATION_MANAGEMENT',
  QuoteManagement = 'QUOTE_MANAGEMENT'
}

export type AccountPermissionScopeOperationFilterInput = {
  eq?: InputMaybe<AccountPermissionScope>;
  in?: InputMaybe<Array<AccountPermissionScope>>;
  neq?: InputMaybe<AccountPermissionScope>;
  nin?: InputMaybe<Array<AccountPermissionScope>>;
};

export type AccountSortInput = {
  aboutUs?: InputMaybe<SortEnumType>;
  address?: InputMaybe<AddressSortInput>;
  cancelSettings?: InputMaybe<CancelSettingsSortInput>;
  discriminator?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  hrAccess?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  logoUrl?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  personioUser?: InputMaybe<SortEnumType>;
  phone?: InputMaybe<SortEnumType>;
  platformAccess?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  termsAndConditionsAccepted?: InputMaybe<SortEnumType>;
  termsAndConditionsUrl?: InputMaybe<SortEnumType>;
  ventoWorkAccess?: InputMaybe<SortEnumType>;
};

export enum AccountStatus {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
  Inactive = 'INACTIVE',
  Invited = 'INVITED'
}

export type AccountStatusOperationFilterInput = {
  eq?: InputMaybe<AccountStatus>;
  in?: InputMaybe<Array<AccountStatus>>;
  neq?: InputMaybe<AccountStatus>;
  nin?: InputMaybe<Array<AccountStatus>>;
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  formattedAddress?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  modifiedDate?: Maybe<Scalars['DateTime']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<User>;
  zip?: Maybe<Scalars['String']>;
};

export type AddressFilterInput = {
  and?: InputMaybe<Array<AddressFilterInput>>;
  city?: InputMaybe<StringOperationFilterInput>;
  country?: InputMaybe<StringOperationFilterInput>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  formattedAddress?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  latitude?: InputMaybe<ComparableNullableOfSingleOperationFilterInput>;
  longitude?: InputMaybe<ComparableNullableOfSingleOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AddressFilterInput>>;
  street?: InputMaybe<StringOperationFilterInput>;
  streetNumber?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
  zip?: InputMaybe<StringOperationFilterInput>;
};

export type AddressSortInput = {
  city?: InputMaybe<SortEnumType>;
  country?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<UserSortInput>;
  creationTime?: InputMaybe<SortEnumType>;
  formattedAddress?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  latitude?: InputMaybe<SortEnumType>;
  longitude?: InputMaybe<SortEnumType>;
  modifiedDate?: InputMaybe<SortEnumType>;
  street?: InputMaybe<SortEnumType>;
  streetNumber?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<UserSortInput>;
  zip?: InputMaybe<SortEnumType>;
};

export type AdminLoginInput = {
  email: Scalars['String'];
};

export type AdminLoginPayload = {
  __typename?: 'AdminLoginPayload';
  string?: Maybe<Scalars['String']>;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER'
}

export type AvailableShiftsModel = {
  __typename?: 'AvailableShiftsModel';
  currentQuantity: Scalars['Int'];
  requiredQuantity: Scalars['Int'];
  shifts: Array<Shift>;
  title: EmployeeSkill;
};

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']>;
  neq?: InputMaybe<Scalars['Boolean']>;
};

export type Branch = {
  __typename?: 'Branch';
  account: Account;
  address?: Maybe<Address>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  quoteChats: Array<QuoteChat>;
  users: Array<User>;
};


export type BranchQuoteChatsArgs = {
  where?: InputMaybe<QuoteChatFilterInput>;
};

export type BranchFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  address?: InputMaybe<AddressFilterInput>;
  and?: InputMaybe<Array<BranchFilterInput>>;
  email?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<BranchFilterInput>>;
  phone?: InputMaybe<StringOperationFilterInput>;
  quoteChats?: InputMaybe<ListFilterInputTypeOfQuoteChatFilterInput>;
  users?: InputMaybe<ListFilterInputTypeOfUserFilterInput>;
};

export type BranchSortInput = {
  account?: InputMaybe<AccountSortInput>;
  address?: InputMaybe<AddressSortInput>;
  email?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isActive?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  phone?: InputMaybe<SortEnumType>;
};

export type CancelSettings = {
  __typename?: 'CancelSettings';
  account: Account;
  accountId: Scalars['UUID'];
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  email: Scalars['String'];
  fromFriday?: Maybe<Scalars['DateTime']>;
  fromMonday?: Maybe<Scalars['DateTime']>;
  fromSaturday?: Maybe<Scalars['DateTime']>;
  fromSunday?: Maybe<Scalars['DateTime']>;
  fromThursday?: Maybe<Scalars['DateTime']>;
  fromTuesday?: Maybe<Scalars['DateTime']>;
  fromWednesday?: Maybe<Scalars['DateTime']>;
  hoursBeforeEvent: Scalars['Float'];
  id: Scalars['ID'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  phone: Scalars['String'];
  toFriday?: Maybe<Scalars['DateTime']>;
  toMonday?: Maybe<Scalars['DateTime']>;
  toSaturday?: Maybe<Scalars['DateTime']>;
  toSunday?: Maybe<Scalars['DateTime']>;
  toThursday?: Maybe<Scalars['DateTime']>;
  toTuesday?: Maybe<Scalars['DateTime']>;
  toWednesday?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<User>;
};

export type CancelSettingsFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  accountId?: InputMaybe<ComparableGuidOperationFilterInput>;
  and?: InputMaybe<Array<CancelSettingsFilterInput>>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  fromFriday?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  fromMonday?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  fromSaturday?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  fromSunday?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  fromThursday?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  fromTuesday?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  fromWednesday?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  hoursBeforeEvent?: InputMaybe<ComparableDoubleOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<CancelSettingsFilterInput>>;
  phone?: InputMaybe<StringOperationFilterInput>;
  toFriday?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  toMonday?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  toSaturday?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  toSunday?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  toThursday?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  toTuesday?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  toWednesday?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export type CancelSettingsSortInput = {
  account?: InputMaybe<AccountSortInput>;
  accountId?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<UserSortInput>;
  creationTime?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  fromFriday?: InputMaybe<SortEnumType>;
  fromMonday?: InputMaybe<SortEnumType>;
  fromSaturday?: InputMaybe<SortEnumType>;
  fromSunday?: InputMaybe<SortEnumType>;
  fromThursday?: InputMaybe<SortEnumType>;
  fromTuesday?: InputMaybe<SortEnumType>;
  fromWednesday?: InputMaybe<SortEnumType>;
  hoursBeforeEvent?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  modifiedDate?: InputMaybe<SortEnumType>;
  phone?: InputMaybe<SortEnumType>;
  toFriday?: InputMaybe<SortEnumType>;
  toMonday?: InputMaybe<SortEnumType>;
  toSaturday?: InputMaybe<SortEnumType>;
  toSunday?: InputMaybe<SortEnumType>;
  toThursday?: InputMaybe<SortEnumType>;
  toTuesday?: InputMaybe<SortEnumType>;
  toWednesday?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<UserSortInput>;
};

export type CanceledShift = {
  __typename?: 'CanceledShift';
  account: Account;
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  employee: Employee;
  id: Scalars['ID'];
  isRead: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  modifiedDate?: Maybe<Scalars['DateTime']>;
  shift: Shift;
  updatedBy?: Maybe<User>;
};

export type CanceledShiftFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  and?: InputMaybe<Array<CanceledShiftFilterInput>>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  employee?: InputMaybe<EmployeeFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  isRead?: InputMaybe<BooleanOperationFilterInput>;
  message?: InputMaybe<StringOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<CanceledShiftFilterInput>>;
  shift?: InputMaybe<ShiftFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export enum ChatCloseReason {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Revoked = 'REVOKED'
}

export type CheckInListModel = {
  __typename?: 'CheckInListModel';
  deployments: Array<Deployment>;
  event: Event;
  listDate: Scalars['DateTime'];
};

export type ChildShiftListModel = {
  __typename?: 'ChildShiftListModel';
  account: Scalars['String'];
  branch: Scalars['String'];
  quantity: Scalars['Int'];
  shiftId: Scalars['ID'];
  type: ShiftDispatchType;
};

export type ComparableByteOperationFilterInput = {
  eq?: InputMaybe<Scalars['Byte']>;
  gt?: InputMaybe<Scalars['Byte']>;
  gte?: InputMaybe<Scalars['Byte']>;
  in?: InputMaybe<Array<Scalars['Byte']>>;
  lt?: InputMaybe<Scalars['Byte']>;
  lte?: InputMaybe<Scalars['Byte']>;
  neq?: InputMaybe<Scalars['Byte']>;
  ngt?: InputMaybe<Scalars['Byte']>;
  ngte?: InputMaybe<Scalars['Byte']>;
  nin?: InputMaybe<Array<Scalars['Byte']>>;
  nlt?: InputMaybe<Scalars['Byte']>;
  nlte?: InputMaybe<Scalars['Byte']>;
};

export type ComparableDateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  ngt?: InputMaybe<Scalars['DateTime']>;
  ngte?: InputMaybe<Scalars['DateTime']>;
  nin?: InputMaybe<Array<Scalars['DateTime']>>;
  nlt?: InputMaybe<Scalars['DateTime']>;
  nlte?: InputMaybe<Scalars['DateTime']>;
};

export type ComparableDoubleOperationFilterInput = {
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  neq?: InputMaybe<Scalars['Float']>;
  ngt?: InputMaybe<Scalars['Float']>;
  ngte?: InputMaybe<Scalars['Float']>;
  nin?: InputMaybe<Array<Scalars['Float']>>;
  nlt?: InputMaybe<Scalars['Float']>;
  nlte?: InputMaybe<Scalars['Float']>;
};

export type ComparableGuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['UUID']>;
  gt?: InputMaybe<Scalars['UUID']>;
  gte?: InputMaybe<Scalars['UUID']>;
  in?: InputMaybe<Array<Scalars['UUID']>>;
  lt?: InputMaybe<Scalars['UUID']>;
  lte?: InputMaybe<Scalars['UUID']>;
  neq?: InputMaybe<Scalars['UUID']>;
  ngt?: InputMaybe<Scalars['UUID']>;
  ngte?: InputMaybe<Scalars['UUID']>;
  nin?: InputMaybe<Array<Scalars['UUID']>>;
  nlt?: InputMaybe<Scalars['UUID']>;
  nlte?: InputMaybe<Scalars['UUID']>;
};

export type ComparableInt32OperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  neq?: InputMaybe<Scalars['Int']>;
  ngt?: InputMaybe<Scalars['Int']>;
  ngte?: InputMaybe<Scalars['Int']>;
  nin?: InputMaybe<Array<Scalars['Int']>>;
  nlt?: InputMaybe<Scalars['Int']>;
  nlte?: InputMaybe<Scalars['Int']>;
};

export type ComparableNullableOfDateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  ngt?: InputMaybe<Scalars['DateTime']>;
  ngte?: InputMaybe<Scalars['DateTime']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  nlt?: InputMaybe<Scalars['DateTime']>;
  nlte?: InputMaybe<Scalars['DateTime']>;
};

export type ComparableNullableOfDecimalOperationFilterInput = {
  eq?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Decimal']>>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  neq?: InputMaybe<Scalars['Decimal']>;
  ngt?: InputMaybe<Scalars['Decimal']>;
  ngte?: InputMaybe<Scalars['Decimal']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Decimal']>>>;
  nlt?: InputMaybe<Scalars['Decimal']>;
  nlte?: InputMaybe<Scalars['Decimal']>;
};

export type ComparableNullableOfDoubleOperationFilterInput = {
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  neq?: InputMaybe<Scalars['Float']>;
  ngt?: InputMaybe<Scalars['Float']>;
  ngte?: InputMaybe<Scalars['Float']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  nlt?: InputMaybe<Scalars['Float']>;
  nlte?: InputMaybe<Scalars['Float']>;
};

export type ComparableNullableOfGuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['UUID']>;
  gt?: InputMaybe<Scalars['UUID']>;
  gte?: InputMaybe<Scalars['UUID']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  lt?: InputMaybe<Scalars['UUID']>;
  lte?: InputMaybe<Scalars['UUID']>;
  neq?: InputMaybe<Scalars['UUID']>;
  ngt?: InputMaybe<Scalars['UUID']>;
  ngte?: InputMaybe<Scalars['UUID']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  nlt?: InputMaybe<Scalars['UUID']>;
  nlte?: InputMaybe<Scalars['UUID']>;
};

export type ComparableNullableOfInt32OperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  neq?: InputMaybe<Scalars['Int']>;
  ngt?: InputMaybe<Scalars['Int']>;
  ngte?: InputMaybe<Scalars['Int']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  nlt?: InputMaybe<Scalars['Int']>;
  nlte?: InputMaybe<Scalars['Int']>;
};

export type ComparableNullableOfSingleOperationFilterInput = {
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  neq?: InputMaybe<Scalars['Float']>;
  ngt?: InputMaybe<Scalars['Float']>;
  ngte?: InputMaybe<Scalars['Float']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  nlt?: InputMaybe<Scalars['Float']>;
  nlte?: InputMaybe<Scalars['Float']>;
};

export type CompleteCheckInListInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type CompleteCheckInListPayload = {
  __typename?: 'CompleteCheckInListPayload';
  event?: Maybe<Event>;
};

export type ConfirmCheckInListInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type ConfirmCheckInListPayload = {
  __typename?: 'ConfirmCheckInListPayload';
  event?: Maybe<Event>;
};

export enum CoventoOrigin {
  Internal = 'INTERNAL',
  Platform = 'PLATFORM',
  SelfCreated = 'SELF_CREATED'
}

export type CoventoOriginOperationFilterInput = {
  eq?: InputMaybe<CoventoOrigin>;
  in?: InputMaybe<Array<CoventoOrigin>>;
  neq?: InputMaybe<CoventoOrigin>;
  nin?: InputMaybe<Array<CoventoOrigin>>;
};

export type CreateAccountInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  hrAccess: Scalars['Boolean'];
  lastName: Scalars['String'];
  name: Scalars['String'];
  permissionInputs: Array<CreateAccountPermissionInput>;
  platformAccess: Scalars['Boolean'];
  type: Scalars['String'];
  ventoWorkAccess: Scalars['Boolean'];
};

export type CreateAccountPayload = {
  __typename?: 'CreateAccountPayload';
  account?: Maybe<Account>;
};

export type CreateAccountPermissionInput = {
  scope: AccountPermissionScope;
  target: NotificationTarget;
};

export type CreateAddressInput = {
  city?: InputMaybe<Scalars['String']>;
  formattedAddress?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  street?: InputMaybe<Scalars['String']>;
  streetNumber?: InputMaybe<Scalars['Int']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type CreateBranchInput = {
  address?: InputMaybe<CreateAddressInput>;
  email?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};

export type CreateBranchPayload = {
  __typename?: 'CreateBranchPayload';
  branch?: Maybe<Branch>;
};

export type CreateCancelSettingsInput = {
  email: Scalars['String'];
  fromFriday?: InputMaybe<Scalars['DateTime']>;
  fromMonday?: InputMaybe<Scalars['DateTime']>;
  fromSaturday?: InputMaybe<Scalars['DateTime']>;
  fromSunday?: InputMaybe<Scalars['DateTime']>;
  fromThursday?: InputMaybe<Scalars['DateTime']>;
  fromTuesday?: InputMaybe<Scalars['DateTime']>;
  fromWednesday?: InputMaybe<Scalars['DateTime']>;
  hoursBeforeEvent: Scalars['Float'];
  phone: Scalars['String'];
  toFriday?: InputMaybe<Scalars['DateTime']>;
  toMonday?: InputMaybe<Scalars['DateTime']>;
  toSaturday?: InputMaybe<Scalars['DateTime']>;
  toSunday?: InputMaybe<Scalars['DateTime']>;
  toThursday?: InputMaybe<Scalars['DateTime']>;
  toTuesday?: InputMaybe<Scalars['DateTime']>;
  toWednesday?: InputMaybe<Scalars['DateTime']>;
};

export type CreateCanceledShiftInput = {
  deploymentId?: InputMaybe<Scalars['ID']>;
  message?: InputMaybe<Scalars['String']>;
};

export type CreateCanceledShiftPayload = {
  __typename?: 'CreateCanceledShiftPayload';
  canceledShift?: Maybe<CanceledShift>;
};

export type CreateCheckInMastersInput = {
  employeeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  eventId?: InputMaybe<Scalars['ID']>;
};

export type CreateCheckInMastersPayload = {
  __typename?: 'CreateCheckInMastersPayload';
  string?: Maybe<Scalars['String']>;
};

export type CreateCustomQuoteItemInput = {
  description: Scalars['String'];
  price: Scalars['Float'];
  quantity: Scalars['Int'];
};

export type CreateCustomerInput = {
  accountRefId?: InputMaybe<Scalars['ID']>;
  branchId?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  prices: Array<CreateCustomerPriceInput>;
};

export type CreateCustomerPayload = {
  __typename?: 'CreateCustomerPayload';
  customer?: Maybe<Customer>;
};

export type CreateCustomerPriceInput = {
  price: Scalars['Float'];
  skill: EmployeeSkill;
};

export type CreateCustomerRequestInput = {
  accountRefId?: InputMaybe<Scalars['ID']>;
  branchId?: InputMaybe<Scalars['ID']>;
  prices: Array<CreateCustomerPriceInput>;
};

export type CreateCustomerRequestPayload = {
  __typename?: 'CreateCustomerRequestPayload';
  customerRequest?: Maybe<CustomerRequest>;
};

export type CreateDeploymentInput = {
  employeeId?: InputMaybe<Scalars['ID']>;
  eventId?: InputMaybe<Scalars['ID']>;
  shiftId?: InputMaybe<Scalars['ID']>;
};

export type CreateDeploymentPayload = {
  __typename?: 'CreateDeploymentPayload';
  deployment?: Maybe<Deployment>;
};

export type CreateDocumentTypeInput = {
  hasExpiration: Scalars['Boolean'];
  name: Scalars['String'];
  relationships: Array<EmploymentRelationship>;
};

export type CreateDocumentTypePayload = {
  __typename?: 'CreateDocumentTypePayload';
  documentType?: Maybe<DocumentType>;
};

export type CreateEmployeeInput = {
  address?: InputMaybe<CreateAddressInput>;
  branchId?: InputMaybe<Scalars['ID']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  employmentRelationship?: InputMaybe<EmploymentRelationship>;
  firstName: Scalars['String'];
  gender?: InputMaybe<Gender>;
  lastName: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  salary?: InputMaybe<Scalars['Decimal']>;
  skills: Array<EmployeeSkill>;
  socialSecurityNumber?: InputMaybe<Scalars['String']>;
  taxId?: InputMaybe<Scalars['String']>;
  teams?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type CreateEmployeePayload = {
  __typename?: 'CreateEmployeePayload';
  employee?: Maybe<Employee>;
};

export type CreateEmployeeTeamInput = {
  branchId?: InputMaybe<Scalars['ID']>;
  employees?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name: Scalars['String'];
};

export type CreateEmployeeTeamPayload = {
  __typename?: 'CreateEmployeeTeamPayload';
  employeeTeam?: Maybe<EmployeeTeam>;
};

export type CreateEventHelpRequestInput = {
  branchId?: InputMaybe<Scalars['ID']>;
  quantity: Scalars['Int'];
  shiftId?: InputMaybe<Scalars['ID']>;
};

export type CreateEventHelpRequestPayload = {
  __typename?: 'CreateEventHelpRequestPayload';
  boolean?: Maybe<Scalars['Boolean']>;
};

export type CreateEventInput = {
  addressInput: CreateAddressInput;
  addressNotes?: InputMaybe<Scalars['String']>;
  branchId: Scalars['ID'];
  comment?: InputMaybe<Scalars['String']>;
  createShiftInputs: Array<CreateShiftInput>;
  description?: InputMaybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  isInternal: Scalars['Boolean'];
  locationId?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  numberOfGuests: Scalars['Int'];
  orderType?: InputMaybe<EventOrderType>;
  personioProjectId?: InputMaybe<Scalars['Int']>;
  personioProjectName?: InputMaybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  type: EventType;
  withTemplate: Scalars['Boolean'];
};

export type CreateEventLocationInput = {
  addressInput: CreateAddressInput;
  floors: Array<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateEventLocationPayload = {
  __typename?: 'CreateEventLocationPayload';
  eventLocation?: Maybe<EventLocation>;
};

export type CreateEventPayload = {
  __typename?: 'CreateEventPayload';
  event?: Maybe<Event>;
};

export type CreateEventRequestInput = {
  shiftId?: InputMaybe<Scalars['ID']>;
};

export type CreateEventRequestPayload = {
  __typename?: 'CreateEventRequestPayload';
  eventRequest?: Maybe<EventRequest>;
};

export type CreateEventTemplateInput = {
  addressInput: CreateAddressInput;
  addressNotes?: InputMaybe<Scalars['String']>;
  branchId?: InputMaybe<Scalars['ID']>;
  comment?: InputMaybe<Scalars['String']>;
  createShiftInputs: Array<CreateShiftInput>;
  description?: InputMaybe<Scalars['String']>;
  locationId?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  numberOfGuests: Scalars['Int'];
  templateName: Scalars['String'];
  type: EventType;
};

export type CreateEventTemplatePayload = {
  __typename?: 'CreateEventTemplatePayload';
  eventTemplate?: Maybe<EventTemplate>;
};

export type CreateManyQuotesInput = {
  customerId?: InputMaybe<Scalars['ID']>;
  customerItems: Array<CustomerItemsInput>;
  eventId?: InputMaybe<Scalars['ID']>;
};

export type CreateManyQuotesPayload = {
  __typename?: 'CreateManyQuotesPayload';
  string?: Maybe<Scalars['String']>;
};

export type CreatePendingDispatchedShiftInput = {
  branchId?: InputMaybe<Scalars['ID']>;
  customerId?: InputMaybe<Scalars['ID']>;
  quantity: Scalars['Int'];
  shiftId: Scalars['ID'];
};

export type CreatePendingDispatchedShiftPayload = {
  __typename?: 'CreatePendingDispatchedShiftPayload';
  pendingDispatchedShift?: Maybe<PendingDispatchedShift>;
};

export type CreateQuoteInput = {
  branchId?: InputMaybe<Scalars['ID']>;
  createCustomQuoteItemInputs: Array<CreateCustomQuoteItemInput>;
  createQuoteItemInputs: Array<CreateQuoteItemInput>;
  eventId?: InputMaybe<Scalars['ID']>;
  message?: InputMaybe<Scalars['String']>;
};

export type CreateQuoteItemInput = {
  price: Scalars['Float'];
  quantity: Scalars['Int'];
  shiftId?: InputMaybe<Scalars['ID']>;
  skill: EmployeeSkill;
};

export type CreateQuotePayload = {
  __typename?: 'CreateQuotePayload';
  quotation?: Maybe<Quotation>;
};

export type CreateShiftInput = {
  employees?: InputMaybe<Array<Scalars['ID']>>;
  endDate: Scalars['DateTime'];
  floorName?: InputMaybe<Scalars['String']>;
  requiredQuantity: Scalars['Int'];
  section?: InputMaybe<Scalars['String']>;
  skill: EmployeeSkill;
  startDate: Scalars['DateTime'];
};

export type CreateShiftsInput = {
  eventId?: InputMaybe<Scalars['ID']>;
  shiftInputs: Array<CreateShiftInput>;
};

export type CreateShiftsPayload = {
  __typename?: 'CreateShiftsPayload';
  boolean?: Maybe<Scalars['Boolean']>;
};

export type CreateUserInput = {
  branchIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  role: Scalars['ID'];
};

export type CreateUserPayload = {
  __typename?: 'CreateUserPayload';
  user?: Maybe<User>;
};

export type CreateUserRoleInput = {
  name: Scalars['String'];
  permissionIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type CreateUserRolePayload = {
  __typename?: 'CreateUserRolePayload';
  userRole?: Maybe<UserRole>;
};

export type CreateUserRolePermissionInput = {
  input: CreateUserRolePermissionTargetInput;
};

export type CreateUserRolePermissionPayload = {
  __typename?: 'CreateUserRolePermissionPayload';
  userRolePermission?: Maybe<UserRolePermission>;
};

export type CreateUserRolePermissionTargetInput = {
  description: Scalars['String'];
  product: NotificationTarget;
  scope: UserRolePermissionScope;
  target: UserRolePermissionTarget;
};

export type Credentials = {
  __typename?: 'Credentials';
  id: Scalars['ID'];
  password?: Maybe<Scalars['String']>;
};

export type CredentialsFilterInput = {
  and?: InputMaybe<Array<CredentialsFilterInput>>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<CredentialsFilterInput>>;
  password?: InputMaybe<StringOperationFilterInput>;
};

export type CredentialsSortInput = {
  id?: InputMaybe<SortEnumType>;
  password?: InputMaybe<SortEnumType>;
};

export type CustomQuoteItem = {
  __typename?: 'CustomQuoteItem';
  account: Account;
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  order?: Maybe<Order>;
  price: Scalars['Float'];
  quantity: Scalars['Int'];
  quotation?: Maybe<Quotation>;
  total: Scalars['Float'];
  updatedBy?: Maybe<User>;
};

export type CustomQuoteItemFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  and?: InputMaybe<Array<CustomQuoteItemFilterInput>>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<CustomQuoteItemFilterInput>>;
  order?: InputMaybe<OrderFilterInput>;
  price?: InputMaybe<ComparableDoubleOperationFilterInput>;
  quantity?: InputMaybe<ComparableInt32OperationFilterInput>;
  quotation?: InputMaybe<QuotationFilterInput>;
  total?: InputMaybe<ComparableDoubleOperationFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export type Customer = {
  __typename?: 'Customer';
  account: Account;
  address?: Maybe<Address>;
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  customerBranch?: Maybe<Branch>;
  customerPrices: Array<CustomerPrice>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  organizer?: Maybe<Organizer>;
  origin: CoventoOrigin;
  serviceProvider?: Maybe<ServiceProvider>;
  updatedBy?: Maybe<User>;
};

export type CustomerFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  address?: InputMaybe<AddressFilterInput>;
  and?: InputMaybe<Array<CustomerFilterInput>>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  customerBranch?: InputMaybe<BranchFilterInput>;
  customerPrices?: InputMaybe<ListFilterInputTypeOfCustomerPriceFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CustomerFilterInput>>;
  organizer?: InputMaybe<OrganizerFilterInput>;
  origin?: InputMaybe<CoventoOriginOperationFilterInput>;
  serviceProvider?: InputMaybe<ServiceProviderFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export type CustomerItemsInput = {
  quantity: Scalars['Int'];
  shiftId?: InputMaybe<Scalars['ID']>;
};

export type CustomerPrice = {
  __typename?: 'CustomerPrice';
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  customers: Array<Customer>;
  id: Scalars['ID'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  price: Scalars['Float'];
  request?: Maybe<CustomerRequest>;
  skill: EmployeeSkill;
  updatedBy?: Maybe<User>;
};

export type CustomerPriceFilterInput = {
  and?: InputMaybe<Array<CustomerPriceFilterInput>>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  customers?: InputMaybe<ListFilterInputTypeOfCustomerFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<CustomerPriceFilterInput>>;
  price?: InputMaybe<ComparableDoubleOperationFilterInput>;
  request?: InputMaybe<CustomerRequestFilterInput>;
  skill?: InputMaybe<EmployeeSkillOperationFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export type CustomerRequest = {
  __typename?: 'CustomerRequest';
  account: Account;
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  customerBranch?: Maybe<Branch>;
  customerPrices: Array<CustomerPrice>;
  id: Scalars['ID'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  organizer?: Maybe<Organizer>;
  serviceProvider?: Maybe<ServiceProvider>;
  status: CustomerRequestStatus;
  updatedBy?: Maybe<User>;
};

export type CustomerRequestFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  and?: InputMaybe<Array<CustomerRequestFilterInput>>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  customerBranch?: InputMaybe<BranchFilterInput>;
  customerPrices?: InputMaybe<ListFilterInputTypeOfCustomerPriceFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<CustomerRequestFilterInput>>;
  organizer?: InputMaybe<OrganizerFilterInput>;
  serviceProvider?: InputMaybe<ServiceProviderFilterInput>;
  status?: InputMaybe<CustomerRequestStatusOperationFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export enum CustomerRequestStatus {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Pending = 'PENDING'
}

export type CustomerRequestStatusOperationFilterInput = {
  eq?: InputMaybe<CustomerRequestStatus>;
  in?: InputMaybe<Array<CustomerRequestStatus>>;
  neq?: InputMaybe<CustomerRequestStatus>;
  nin?: InputMaybe<Array<CustomerRequestStatus>>;
};

export type CustomerSortInput = {
  account?: InputMaybe<AccountSortInput>;
  address?: InputMaybe<AddressSortInput>;
  createdBy?: InputMaybe<UserSortInput>;
  creationTime?: InputMaybe<SortEnumType>;
  customerBranch?: InputMaybe<BranchSortInput>;
  email?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isActive?: InputMaybe<SortEnumType>;
  modifiedDate?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  organizer?: InputMaybe<OrganizerSortInput>;
  origin?: InputMaybe<SortEnumType>;
  serviceProvider?: InputMaybe<ServiceProviderSortInput>;
  updatedBy?: InputMaybe<UserSortInput>;
};

export type DeleteAccountSkillsInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type DeleteAccountSkillsPayload = {
  __typename?: 'DeleteAccountSkillsPayload';
  string?: Maybe<Scalars['String']>;
};

export type DeleteDeploymentInput = {
  deploymentId?: InputMaybe<Scalars['ID']>;
};

export type DeleteDeploymentPayload = {
  __typename?: 'DeleteDeploymentPayload';
  string?: Maybe<Scalars['String']>;
};

export type DeleteDocumentTypeInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type DeleteDocumentTypePayload = {
  __typename?: 'DeleteDocumentTypePayload';
  boolean?: Maybe<Scalars['Boolean']>;
};

export type DeleteEmployeeDocumentInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type DeleteEmployeeDocumentPayload = {
  __typename?: 'DeleteEmployeeDocumentPayload';
  boolean?: Maybe<Scalars['Boolean']>;
};

export type DeleteEmployeeTeamInput = {
  teamId?: InputMaybe<Scalars['ID']>;
};

export type DeleteEmployeeTeamPayload = {
  __typename?: 'DeleteEmployeeTeamPayload';
  boolean?: Maybe<Scalars['Boolean']>;
};

export type DeleteEventDocumentInput = {
  documentId: Scalars['ID'];
};

export type DeleteEventDocumentPayload = {
  __typename?: 'DeleteEventDocumentPayload';
  boolean?: Maybe<Scalars['Boolean']>;
};

export type DeleteEventRequestInput = {
  requestId?: InputMaybe<Scalars['ID']>;
};

export type DeleteEventRequestPayload = {
  __typename?: 'DeleteEventRequestPayload';
  string?: Maybe<Scalars['String']>;
};

export type DeletePendingDispatchedShiftInput = {
  shiftId: Scalars['ID'];
};

export type DeletePendingDispatchedShiftPayload = {
  __typename?: 'DeletePendingDispatchedShiftPayload';
  boolean?: Maybe<Scalars['Boolean']>;
};

export type Department = {
  __typename?: 'Department';
  account: Account;
  address?: Maybe<Address>;
  branch: Branch;
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  employees: Array<Employee>;
  id: Scalars['ID'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedBy?: Maybe<User>;
};

export type DepartmentFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  address?: InputMaybe<AddressFilterInput>;
  and?: InputMaybe<Array<DepartmentFilterInput>>;
  branch?: InputMaybe<BranchFilterInput>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  employees?: InputMaybe<ListFilterInputTypeOfEmployeeFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DepartmentFilterInput>>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export type Deployment = {
  __typename?: 'Deployment';
  breakTime?: Maybe<Scalars['Int']>;
  checkInShift?: Maybe<Shift>;
  checkInTime?: Maybe<Scalars['DateTime']>;
  checkInTimeSetBy?: Maybe<Account>;
  checkOutTime?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  employee: Employee;
  employeeStatus: DeploymentEmployeeStatus;
  event: Event;
  id: Scalars['ID'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  oldCheckInTime?: Maybe<Scalars['DateTime']>;
  oldCheckOutTime?: Maybe<Scalars['DateTime']>;
  parentEvent?: Maybe<Event>;
  parentEventId?: Maybe<Scalars['ID']>;
  pendingCheckInTime?: Maybe<Scalars['DateTime']>;
  pendingCheckOutTime?: Maybe<Scalars['DateTime']>;
  personioAttendanceId?: Maybe<Scalars['Int']>;
  providedBy: Account;
  shift: Shift;
  status: DeploymentStatus;
  updatedBy?: Maybe<User>;
  workedHours: Scalars['Float'];
};

export type DeploymentByMonthModel = {
  __typename?: 'DeploymentByMonthModel';
  month: Scalars['String'];
  totalDeployments: Scalars['Int'];
  totalHours: Scalars['Float'];
  year: Scalars['String'];
};

export enum DeploymentEmployeeStatus {
  Confirmed = 'CONFIRMED',
  NotShowUp = 'NOT_SHOW_UP',
  NoTimes = 'NO_TIMES',
  Pending = 'PENDING',
  Published = 'PUBLISHED',
  Scheduled = 'SCHEDULED'
}

export type DeploymentEmployeeStatusOperationFilterInput = {
  eq?: InputMaybe<DeploymentEmployeeStatus>;
  in?: InputMaybe<Array<DeploymentEmployeeStatus>>;
  neq?: InputMaybe<DeploymentEmployeeStatus>;
  nin?: InputMaybe<Array<DeploymentEmployeeStatus>>;
};

export type DeploymentFilterInput = {
  and?: InputMaybe<Array<DeploymentFilterInput>>;
  breakTime?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  checkInShift?: InputMaybe<ShiftFilterInput>;
  checkInTime?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  checkInTimeSetBy?: InputMaybe<AccountFilterInput>;
  checkOutTime?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  comment?: InputMaybe<StringOperationFilterInput>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  employee?: InputMaybe<EmployeeFilterInput>;
  employeeStatus?: InputMaybe<DeploymentEmployeeStatusOperationFilterInput>;
  event?: InputMaybe<EventFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  oldCheckInTime?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  oldCheckOutTime?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<DeploymentFilterInput>>;
  parentEvent?: InputMaybe<EventFilterInput>;
  parentEventId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  pendingCheckInTime?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  pendingCheckOutTime?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  personioAttendanceId?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  providedBy?: InputMaybe<AccountFilterInput>;
  shift?: InputMaybe<ShiftFilterInput>;
  status?: InputMaybe<DeploymentStatusOperationFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
  workedHours?: InputMaybe<ComparableDoubleOperationFilterInput>;
};

export type DeploymentSortInput = {
  breakTime?: InputMaybe<SortEnumType>;
  checkInShift?: InputMaybe<ShiftSortInput>;
  checkInTime?: InputMaybe<SortEnumType>;
  checkInTimeSetBy?: InputMaybe<AccountSortInput>;
  checkOutTime?: InputMaybe<SortEnumType>;
  comment?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<UserSortInput>;
  creationTime?: InputMaybe<SortEnumType>;
  employee?: InputMaybe<EmployeeSortInput>;
  employeeStatus?: InputMaybe<SortEnumType>;
  event?: InputMaybe<EventSortInput>;
  id?: InputMaybe<SortEnumType>;
  modifiedDate?: InputMaybe<SortEnumType>;
  oldCheckInTime?: InputMaybe<SortEnumType>;
  oldCheckOutTime?: InputMaybe<SortEnumType>;
  parentEvent?: InputMaybe<EventSortInput>;
  parentEventId?: InputMaybe<SortEnumType>;
  pendingCheckInTime?: InputMaybe<SortEnumType>;
  pendingCheckOutTime?: InputMaybe<SortEnumType>;
  personioAttendanceId?: InputMaybe<SortEnumType>;
  providedBy?: InputMaybe<AccountSortInput>;
  shift?: InputMaybe<ShiftSortInput>;
  status?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<UserSortInput>;
  workedHours?: InputMaybe<SortEnumType>;
};

export enum DeploymentStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Confirmed = 'CONFIRMED',
  Pending = 'PENDING',
  ReadyToTransmit = 'READY_TO_TRANSMIT',
  Released = 'RELEASED',
  Scheduled = 'SCHEDULED'
}

export type DeploymentStatusOperationFilterInput = {
  eq?: InputMaybe<DeploymentStatus>;
  in?: InputMaybe<Array<DeploymentStatus>>;
  neq?: InputMaybe<DeploymentStatus>;
  nin?: InputMaybe<Array<DeploymentStatus>>;
};

export type DeploymentsShiftModel = {
  __typename?: 'DeploymentsShiftModel';
  deployments: Array<Deployment>;
  shifts: Array<Shift>;
};

export type DocumentType = {
  __typename?: 'DocumentType';
  account: Account;
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  employeeDocuments: Array<EmployeeDocument>;
  employmentRelations: Array<EmploymentRelationModel>;
  hasExpiration: Scalars['Boolean'];
  id: Scalars['ID'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedBy?: Maybe<User>;
};

export type DocumentTypeFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  and?: InputMaybe<Array<DocumentTypeFilterInput>>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  employeeDocuments?: InputMaybe<ListFilterInputTypeOfEmployeeDocumentFilterInput>;
  employmentRelations?: InputMaybe<ListFilterInputTypeOfEmploymentRelationModelFilterInput>;
  hasExpiration?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DocumentTypeFilterInput>>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export type Employee = {
  __typename?: 'Employee';
  account: Account;
  accountId: Scalars['UUID'];
  address?: Maybe<Address>;
  branch: Branch;
  branchId: Scalars['ID'];
  checkInMasters: Array<Event>;
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  credentials: Credentials;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  departments: Array<Department>;
  documents: Array<EmployeeDocument>;
  email: Scalars['String'];
  employmentRelationship?: Maybe<EmploymentRelationship>;
  expoPushToken?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  gender?: Maybe<Gender>;
  id: Scalars['ID'];
  isOfAge?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  personioId?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['String']>;
  pictureUrl?: Maybe<Scalars['String']>;
  preFilledShifts: Array<Shift>;
  salary?: Maybe<Scalars['Decimal']>;
  skills: Array<EmployeeSkillModel>;
  socialSecurityNumber?: Maybe<Scalars['String']>;
  staffNumber: Scalars['Int'];
  status: EmployeeStatus;
  taxId?: Maybe<Scalars['String']>;
  teams: Array<EmployeeTeam>;
  updatedBy?: Maybe<User>;
};

export type EmployeeDashboardModel = {
  __typename?: 'EmployeeDashboardModel';
  completedDeployments: Scalars['Int'];
  nextDeployment?: Maybe<Deployment>;
  openRequests: Scalars['Int'];
  pendingDeployments: Scalars['Int'];
  publishedDeployments: Scalars['Int'];
  status?: Maybe<EmploymentRelationship>;
  workedHours: Scalars['Float'];
};

export type EmployeeDocument = {
  __typename?: 'EmployeeDocument';
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  employee: Employee;
  id: Scalars['ID'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  type: DocumentType;
  updatedBy?: Maybe<User>;
  url: Scalars['String'];
  validFrom?: Maybe<Scalars['DateTime']>;
  validTo?: Maybe<Scalars['DateTime']>;
};

export type EmployeeDocumentFilterInput = {
  and?: InputMaybe<Array<EmployeeDocumentFilterInput>>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  employee?: InputMaybe<EmployeeFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<EmployeeDocumentFilterInput>>;
  type?: InputMaybe<DocumentTypeFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
  url?: InputMaybe<StringOperationFilterInput>;
  validFrom?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  validTo?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
};

export type EmployeeDocumentModel = {
  __typename?: 'EmployeeDocumentModel';
  document?: Maybe<EmployeeDocument>;
  type: DocumentType;
};

export type EmployeeFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  accountId?: InputMaybe<ComparableGuidOperationFilterInput>;
  address?: InputMaybe<AddressFilterInput>;
  and?: InputMaybe<Array<EmployeeFilterInput>>;
  branch?: InputMaybe<BranchFilterInput>;
  branchId?: InputMaybe<ComparableGuidOperationFilterInput>;
  checkInMasters?: InputMaybe<ListFilterInputTypeOfEventFilterInput>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  credentials?: InputMaybe<CredentialsFilterInput>;
  dateOfBirth?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  departments?: InputMaybe<ListFilterInputTypeOfDepartmentFilterInput>;
  documents?: InputMaybe<ListFilterInputTypeOfEmployeeDocumentFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  employmentRelationship?: InputMaybe<NullableOfEmploymentRelationshipOperationFilterInput>;
  expoPushToken?: InputMaybe<StringOperationFilterInput>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  gender?: InputMaybe<NullableOfGenderOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  isOfAge?: InputMaybe<BooleanOperationFilterInput>;
  lastName?: InputMaybe<StringOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<EmployeeFilterInput>>;
  personioId?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  phone?: InputMaybe<StringOperationFilterInput>;
  pictureUrl?: InputMaybe<StringOperationFilterInput>;
  preFilledShifts?: InputMaybe<ListFilterInputTypeOfShiftFilterInput>;
  salary?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  skills?: InputMaybe<ListFilterInputTypeOfEmployeeSkillModelFilterInput>;
  socialSecurityNumber?: InputMaybe<StringOperationFilterInput>;
  staffNumber?: InputMaybe<ComparableInt32OperationFilterInput>;
  status?: InputMaybe<EmployeeStatusOperationFilterInput>;
  taxId?: InputMaybe<StringOperationFilterInput>;
  teams?: InputMaybe<ListFilterInputTypeOfEmployeeTeamFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export type EmployeeLoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type EmployeeLoginPayload = {
  __typename?: 'EmployeeLoginPayload';
  string?: Maybe<Scalars['String']>;
};

export enum EmployeeSkill {
  Barkeeper = 'BARKEEPER',
  BarManager = 'BAR_MANAGER',
  Buffet = 'BUFFET',
  CampManager = 'CAMP_MANAGER',
  Cashier = 'CASHIER',
  Counter = 'COUNTER',
  Dishwasher = 'DISHWASHER',
  EventManager = 'EVENT_MANAGER',
  Hostess = 'HOSTESS',
  KitchenHelp = 'KITCHEN_HELP',
  LodgeService = 'LODGE_SERVICE',
  Logistician = 'LOGISTICIAN',
  LogisticManager = 'LOGISTIC_MANAGER',
  Server = 'SERVER',
  ServiceA = 'SERVICE_A',
  ServiceB = 'SERVICE_B',
  ServiceC = 'SERVICE_C',
  ServiceManager = 'SERVICE_MANAGER',
  ServiceProfessional = 'SERVICE_PROFESSIONAL',
  ShopManager = 'SHOP_MANAGER',
  Tapster = 'TAPSTER',
  TeamManager = 'TEAM_MANAGER',
  Trainer = 'TRAINER'
}

export type EmployeeSkillModel = {
  __typename?: 'EmployeeSkillModel';
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  document?: Maybe<EventDocument>;
  employee?: Maybe<Employee>;
  event?: Maybe<Event>;
  id: Scalars['ID'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  serviceProvider?: Maybe<ServiceProvider>;
  skill: EmployeeSkill;
  updatedBy?: Maybe<User>;
};

export type EmployeeSkillModelFilterInput = {
  and?: InputMaybe<Array<EmployeeSkillModelFilterInput>>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  document?: InputMaybe<EventDocumentFilterInput>;
  employee?: InputMaybe<EmployeeFilterInput>;
  event?: InputMaybe<EventFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<EmployeeSkillModelFilterInput>>;
  serviceProvider?: InputMaybe<ServiceProviderFilterInput>;
  skill?: InputMaybe<EmployeeSkillOperationFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export type EmployeeSkillOperationFilterInput = {
  eq?: InputMaybe<EmployeeSkill>;
  in?: InputMaybe<Array<EmployeeSkill>>;
  neq?: InputMaybe<EmployeeSkill>;
  nin?: InputMaybe<Array<EmployeeSkill>>;
};

export type EmployeeSortInput = {
  account?: InputMaybe<AccountSortInput>;
  accountId?: InputMaybe<SortEnumType>;
  address?: InputMaybe<AddressSortInput>;
  branch?: InputMaybe<BranchSortInput>;
  branchId?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<UserSortInput>;
  creationTime?: InputMaybe<SortEnumType>;
  credentials?: InputMaybe<CredentialsSortInput>;
  dateOfBirth?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  employmentRelationship?: InputMaybe<SortEnumType>;
  expoPushToken?: InputMaybe<SortEnumType>;
  firstName?: InputMaybe<SortEnumType>;
  gender?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isOfAge?: InputMaybe<SortEnumType>;
  lastName?: InputMaybe<SortEnumType>;
  modifiedDate?: InputMaybe<SortEnumType>;
  personioId?: InputMaybe<SortEnumType>;
  phone?: InputMaybe<SortEnumType>;
  pictureUrl?: InputMaybe<SortEnumType>;
  salary?: InputMaybe<SortEnumType>;
  socialSecurityNumber?: InputMaybe<SortEnumType>;
  staffNumber?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  taxId?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<UserSortInput>;
};

export enum EmployeeStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Invalid = 'INVALID',
  Invited = 'INVITED',
  Pending = 'PENDING'
}

export type EmployeeStatusOperationFilterInput = {
  eq?: InputMaybe<EmployeeStatus>;
  in?: InputMaybe<Array<EmployeeStatus>>;
  neq?: InputMaybe<EmployeeStatus>;
  nin?: InputMaybe<Array<EmployeeStatus>>;
};

export type EmployeeTeam = {
  __typename?: 'EmployeeTeam';
  account: Account;
  branch: Branch;
  branchId: Scalars['UUID'];
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  employees: Array<Employee>;
  id: Scalars['ID'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  pubslishedEvents: Array<Event>;
  updatedBy?: Maybe<User>;
};

export type EmployeeTeamFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  and?: InputMaybe<Array<EmployeeTeamFilterInput>>;
  branch?: InputMaybe<BranchFilterInput>;
  branchId?: InputMaybe<ComparableGuidOperationFilterInput>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  employees?: InputMaybe<ListFilterInputTypeOfEmployeeFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<EmployeeTeamFilterInput>>;
  pubslishedEvents?: InputMaybe<ListFilterInputTypeOfEventFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export type EmploymentRelationModel = {
  __typename?: 'EmploymentRelationModel';
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  employmentRelationship: EmploymentRelationship;
  id: Scalars['ID'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<User>;
};

export type EmploymentRelationModelFilterInput = {
  and?: InputMaybe<Array<EmploymentRelationModelFilterInput>>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  employmentRelationship?: InputMaybe<EmploymentRelationshipOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<EmploymentRelationModelFilterInput>>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export enum EmploymentRelationship {
  Freelancer = 'FREELANCER',
  Full = 'FULL',
  Midi = 'MIDI',
  Mini = 'MINI',
  Short = 'SHORT',
  Student = 'STUDENT'
}

export type EmploymentRelationshipOperationFilterInput = {
  eq?: InputMaybe<EmploymentRelationship>;
  in?: InputMaybe<Array<EmploymentRelationship>>;
  neq?: InputMaybe<EmploymentRelationship>;
  nin?: InputMaybe<Array<EmploymentRelationship>>;
};

export type Event = {
  __typename?: 'Event';
  account: Account;
  address: Address;
  addressNotes?: Maybe<Scalars['String']>;
  branch: Branch;
  branchId: Scalars['ID'];
  checkInListCompleted: Scalars['Boolean'];
  checkInListCompletedAt?: Maybe<Scalars['DateTime']>;
  checkInListCompletedBy?: Maybe<User>;
  checkInListConfirmed: Scalars['Boolean'];
  checkInListConfirmedAt?: Maybe<Scalars['DateTime']>;
  checkInListConfirmedBy?: Maybe<User>;
  checkInListDownloadUrl?: Maybe<Scalars['String']>;
  checkInListPdfId?: Maybe<Scalars['String']>;
  checkInListReleased: Scalars['Boolean'];
  checkInListReleasedAt?: Maybe<Scalars['DateTime']>;
  checkInListReleasedBy?: Maybe<User>;
  checkInMasters: Array<Employee>;
  childEvents: Array<Event>;
  comment?: Maybe<Scalars['String']>;
  contact: User;
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  deployments: Array<Deployment>;
  description?: Maybe<Scalars['String']>;
  employeeLeasingUrl?: Maybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  eventNumber: Scalars['Int'];
  hasActiveCancelRequest: Scalars['Boolean'];
  id: Scalars['ID'];
  isInternal: Scalars['Boolean'];
  location?: Maybe<EventLocation>;
  locationId?: Maybe<Scalars['UUID']>;
  message?: Maybe<Scalars['String']>;
  modifiedDate?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  numberOfGuests: Scalars['Int'];
  orderType?: Maybe<EventOrderType>;
  orders: Array<Order>;
  organizer?: Maybe<Organizer>;
  origin: CoventoOrigin;
  parentEvent?: Maybe<Event>;
  personioProjectId?: Maybe<Scalars['Int']>;
  personioProjectName?: Maybe<Scalars['String']>;
  publishedForAllEmployees: Scalars['Boolean'];
  publishedTeams: Array<EmployeeTeam>;
  quotations: Array<Quotation>;
  quoteChats: Array<QuoteChat>;
  serviceProvider?: Maybe<ServiceProvider>;
  shifts: Array<Shift>;
  startDate: Scalars['DateTime'];
  status: EventStatus;
  type: EventType;
  updatedBy?: Maybe<User>;
};


export type EventQuoteChatsArgs = {
  where?: InputMaybe<QuoteChatFilterInput>;
};

export type EventAssignmentProgressModel = {
  __typename?: 'EventAssignmentProgressModel';
  progress: Scalars['String'];
  publishStatus: Scalars['String'];
};

export type EventCancelRequest = {
  __typename?: 'EventCancelRequest';
  account: Account;
  chat: QuoteChat;
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  event: Event;
  id: Scalars['ID'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  order: Order;
  status: QuoteStatus;
  updatedBy?: Maybe<User>;
};

export type EventCancelRequestFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  and?: InputMaybe<Array<EventCancelRequestFilterInput>>;
  chat?: InputMaybe<QuoteChatFilterInput>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  event?: InputMaybe<EventFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<EventCancelRequestFilterInput>>;
  order?: InputMaybe<OrderFilterInput>;
  status?: InputMaybe<QuoteStatusOperationFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export type EventCancelRequestSortInput = {
  account?: InputMaybe<AccountSortInput>;
  chat?: InputMaybe<QuoteChatSortInput>;
  createdBy?: InputMaybe<UserSortInput>;
  creationTime?: InputMaybe<SortEnumType>;
  event?: InputMaybe<EventSortInput>;
  id?: InputMaybe<SortEnumType>;
  modifiedDate?: InputMaybe<SortEnumType>;
  order?: InputMaybe<OrderSortInput>;
  status?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<UserSortInput>;
};

export type EventCompleteDataModel = {
  __typename?: 'EventCompleteDataModel';
  confirmedDeployments: Scalars['Int'];
  insertedDeployments: Scalars['Int'];
  totalDeployments: Scalars['Int'];
  totalRequiredDeployments: Scalars['Int'];
};

export type EventDashboardStatsModel = {
  __typename?: 'EventDashboardStatsModel';
  account?: Maybe<Scalars['String']>;
  coasts?: Maybe<Scalars['Float']>;
  employees?: Maybe<Scalars['Int']>;
  hours?: Maybe<Scalars['Float']>;
  plannedCoasts: Scalars['Float'];
  plannedEmployees: Scalars['Int'];
  plannedHours: Scalars['Float'];
  plannedTotalCoasts: Scalars['Float'];
  plannedTotalEmployees: Scalars['Int'];
  plannedTotalHours: Scalars['Float'];
  shifts: Array<EventDashboardStatsModel>;
  skill?: Maybe<EmployeeSkill>;
  totalCoasts?: Maybe<Scalars['Float']>;
  totalEmployees?: Maybe<Scalars['Int']>;
  totalHours?: Maybe<Scalars['Float']>;
};

export type EventDocument = {
  __typename?: 'EventDocument';
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  event: Event;
  id: Scalars['ID'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedBy?: Maybe<User>;
  url: Scalars['String'];
  visibleTo: Array<EmployeeSkillModel>;
};

export type EventDocumentFilterInput = {
  and?: InputMaybe<Array<EventDocumentFilterInput>>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  event?: InputMaybe<EventFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<EventDocumentFilterInput>>;
  updatedBy?: InputMaybe<UserFilterInput>;
  url?: InputMaybe<StringOperationFilterInput>;
  visibleTo?: InputMaybe<ListFilterInputTypeOfEmployeeSkillModelFilterInput>;
};

export type EventFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  address?: InputMaybe<AddressFilterInput>;
  addressNotes?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<EventFilterInput>>;
  branch?: InputMaybe<BranchFilterInput>;
  branchId?: InputMaybe<ComparableGuidOperationFilterInput>;
  checkInListCompleted?: InputMaybe<BooleanOperationFilterInput>;
  checkInListCompletedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  checkInListCompletedBy?: InputMaybe<UserFilterInput>;
  checkInListConfirmed?: InputMaybe<BooleanOperationFilterInput>;
  checkInListConfirmedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  checkInListConfirmedBy?: InputMaybe<UserFilterInput>;
  checkInListDownloadUrl?: InputMaybe<StringOperationFilterInput>;
  checkInListPdfId?: InputMaybe<StringOperationFilterInput>;
  checkInListReleased?: InputMaybe<BooleanOperationFilterInput>;
  checkInListReleasedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  checkInListReleasedBy?: InputMaybe<UserFilterInput>;
  checkInMasters?: InputMaybe<ListFilterInputTypeOfEmployeeFilterInput>;
  childEvents?: InputMaybe<ListFilterInputTypeOfEventFilterInput>;
  comment?: InputMaybe<StringOperationFilterInput>;
  contact?: InputMaybe<UserFilterInput>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  deployments?: InputMaybe<ListFilterInputTypeOfDeploymentFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  employeeLeasingUrl?: InputMaybe<StringOperationFilterInput>;
  endDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  eventNumber?: InputMaybe<ComparableInt32OperationFilterInput>;
  hasActiveCancelRequest?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  isInternal?: InputMaybe<BooleanOperationFilterInput>;
  location?: InputMaybe<EventLocationFilterInput>;
  locationId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  message?: InputMaybe<StringOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  numberOfGuests?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<EventFilterInput>>;
  orderType?: InputMaybe<NullableOfEventOrderTypeOperationFilterInput>;
  orders?: InputMaybe<ListFilterInputTypeOfOrderFilterInput>;
  organizer?: InputMaybe<OrganizerFilterInput>;
  origin?: InputMaybe<CoventoOriginOperationFilterInput>;
  parentEvent?: InputMaybe<EventFilterInput>;
  personioProjectId?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  personioProjectName?: InputMaybe<StringOperationFilterInput>;
  publishedForAllEmployees?: InputMaybe<BooleanOperationFilterInput>;
  publishedTeams?: InputMaybe<ListFilterInputTypeOfEmployeeTeamFilterInput>;
  quotations?: InputMaybe<ListFilterInputTypeOfQuotationFilterInput>;
  quoteChats?: InputMaybe<ListFilterInputTypeOfQuoteChatFilterInput>;
  serviceProvider?: InputMaybe<ServiceProviderFilterInput>;
  shifts?: InputMaybe<ListFilterInputTypeOfShiftFilterInput>;
  startDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  status?: InputMaybe<EventStatusOperationFilterInput>;
  type?: InputMaybe<EventTypeOperationFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export type EventHelpRequest = {
  __typename?: 'EventHelpRequest';
  account: Account;
  branch: Branch;
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  event: Event;
  id: Scalars['ID'];
  isCancel: Scalars['Boolean'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  quantity: Scalars['Int'];
  shift: Shift;
  status: EventRequestStatus;
  updatedBy?: Maybe<User>;
};

export type EventHelpRequestFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  and?: InputMaybe<Array<EventHelpRequestFilterInput>>;
  branch?: InputMaybe<BranchFilterInput>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  event?: InputMaybe<EventFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  isCancel?: InputMaybe<BooleanOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<EventHelpRequestFilterInput>>;
  quantity?: InputMaybe<ComparableInt32OperationFilterInput>;
  shift?: InputMaybe<ShiftFilterInput>;
  status?: InputMaybe<EventRequestStatusOperationFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export type EventLocation = {
  __typename?: 'EventLocation';
  account: Account;
  address: Address;
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  floors: Array<Scalars['String']>;
  id: Scalars['ID'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedBy?: Maybe<User>;
};

export type EventLocationFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  address?: InputMaybe<AddressFilterInput>;
  and?: InputMaybe<Array<EventLocationFilterInput>>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  floors?: InputMaybe<ListStringOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<EventLocationFilterInput>>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export type EventLocationSortInput = {
  account?: InputMaybe<AccountSortInput>;
  address?: InputMaybe<AddressSortInput>;
  createdBy?: InputMaybe<UserSortInput>;
  creationTime?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  modifiedDate?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<UserSortInput>;
};

export enum EventOrderType {
  Forecast = 'FORECAST',
  Intern = 'INTERN',
  Order = 'ORDER'
}

export type EventRequest = {
  __typename?: 'EventRequest';
  account: Account;
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  employee: Employee;
  event: Event;
  id: Scalars['ID'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  shift: Shift;
  status: EventRequestStatus;
  updatedBy?: Maybe<User>;
};

export type EventRequestFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  and?: InputMaybe<Array<EventRequestFilterInput>>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  employee?: InputMaybe<EmployeeFilterInput>;
  event?: InputMaybe<EventFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<EventRequestFilterInput>>;
  shift?: InputMaybe<ShiftFilterInput>;
  status?: InputMaybe<EventRequestStatusOperationFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export enum EventRequestStatus {
  Confirmed = 'CONFIRMED',
  Declined = 'DECLINED',
  Pending = 'PENDING'
}

export type EventRequestStatusOperationFilterInput = {
  eq?: InputMaybe<EventRequestStatus>;
  in?: InputMaybe<Array<EventRequestStatus>>;
  neq?: InputMaybe<EventRequestStatus>;
  nin?: InputMaybe<Array<EventRequestStatus>>;
};

export type EventSortInput = {
  account?: InputMaybe<AccountSortInput>;
  address?: InputMaybe<AddressSortInput>;
  addressNotes?: InputMaybe<SortEnumType>;
  branch?: InputMaybe<BranchSortInput>;
  branchId?: InputMaybe<SortEnumType>;
  checkInListCompleted?: InputMaybe<SortEnumType>;
  checkInListCompletedAt?: InputMaybe<SortEnumType>;
  checkInListCompletedBy?: InputMaybe<UserSortInput>;
  checkInListConfirmed?: InputMaybe<SortEnumType>;
  checkInListConfirmedAt?: InputMaybe<SortEnumType>;
  checkInListConfirmedBy?: InputMaybe<UserSortInput>;
  checkInListDownloadUrl?: InputMaybe<SortEnumType>;
  checkInListPdfId?: InputMaybe<SortEnumType>;
  checkInListReleased?: InputMaybe<SortEnumType>;
  checkInListReleasedAt?: InputMaybe<SortEnumType>;
  checkInListReleasedBy?: InputMaybe<UserSortInput>;
  comment?: InputMaybe<SortEnumType>;
  contact?: InputMaybe<UserSortInput>;
  createdBy?: InputMaybe<UserSortInput>;
  creationTime?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  employeeLeasingUrl?: InputMaybe<SortEnumType>;
  endDate?: InputMaybe<SortEnumType>;
  eventNumber?: InputMaybe<SortEnumType>;
  hasActiveCancelRequest?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isInternal?: InputMaybe<SortEnumType>;
  location?: InputMaybe<EventLocationSortInput>;
  locationId?: InputMaybe<SortEnumType>;
  message?: InputMaybe<SortEnumType>;
  modifiedDate?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  numberOfGuests?: InputMaybe<SortEnumType>;
  orderType?: InputMaybe<SortEnumType>;
  organizer?: InputMaybe<OrganizerSortInput>;
  origin?: InputMaybe<SortEnumType>;
  parentEvent?: InputMaybe<EventSortInput>;
  personioProjectId?: InputMaybe<SortEnumType>;
  personioProjectName?: InputMaybe<SortEnumType>;
  publishedForAllEmployees?: InputMaybe<SortEnumType>;
  serviceProvider?: InputMaybe<ServiceProviderSortInput>;
  startDate?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<UserSortInput>;
};

export enum EventStatus {
  Active = 'ACTIVE',
  ActiveCancelRequest = 'ACTIVE_CANCEL_REQUEST',
  Blocked = 'BLOCKED',
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  ReadyToComplete = 'READY_TO_COMPLETE',
  WaitingForComplete = 'WAITING_FOR_COMPLETE'
}

export type EventStatusOperationFilterInput = {
  eq?: InputMaybe<EventStatus>;
  in?: InputMaybe<Array<EventStatus>>;
  neq?: InputMaybe<EventStatus>;
  nin?: InputMaybe<Array<EventStatus>>;
};

export type EventTemplate = {
  __typename?: 'EventTemplate';
  account: Account;
  address: Address;
  addressNotes?: Maybe<Scalars['String']>;
  branch: Branch;
  branchId: Scalars['ID'];
  comment?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  location?: Maybe<EventLocation>;
  locationId?: Maybe<Scalars['UUID']>;
  modifiedDate?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  numberOfGuests: Scalars['Int'];
  shifts: Array<Shift>;
  templateName: Scalars['String'];
  type: EventType;
  updatedBy?: Maybe<User>;
};

export type EventTemplateFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  address?: InputMaybe<AddressFilterInput>;
  addressNotes?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<EventTemplateFilterInput>>;
  branch?: InputMaybe<BranchFilterInput>;
  branchId?: InputMaybe<ComparableGuidOperationFilterInput>;
  comment?: InputMaybe<StringOperationFilterInput>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  location?: InputMaybe<EventLocationFilterInput>;
  locationId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  numberOfGuests?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<EventTemplateFilterInput>>;
  shifts?: InputMaybe<ListFilterInputTypeOfShiftFilterInput>;
  templateName?: InputMaybe<StringOperationFilterInput>;
  type?: InputMaybe<EventTypeOperationFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export type EventTemplateSortInput = {
  account?: InputMaybe<AccountSortInput>;
  address?: InputMaybe<AddressSortInput>;
  addressNotes?: InputMaybe<SortEnumType>;
  branch?: InputMaybe<BranchSortInput>;
  branchId?: InputMaybe<SortEnumType>;
  comment?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<UserSortInput>;
  creationTime?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  location?: InputMaybe<EventLocationSortInput>;
  locationId?: InputMaybe<SortEnumType>;
  modifiedDate?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  numberOfGuests?: InputMaybe<SortEnumType>;
  templateName?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<UserSortInput>;
};

export enum EventType {
  Birthday = 'BIRTHDAY',
  Company = 'COMPANY',
  Concert = 'CONCERT',
  Fair = 'FAIR',
  Festival = 'FESTIVAL',
  Miscellaneous = 'MISCELLANEOUS',
  Sport = 'SPORT',
  Wedding = 'WEDDING'
}

export type EventTypeOperationFilterInput = {
  eq?: InputMaybe<EventType>;
  in?: InputMaybe<Array<EventType>>;
  neq?: InputMaybe<EventType>;
  nin?: InputMaybe<Array<EventType>>;
};

export type FinalizeQuoteInput = {
  id?: InputMaybe<Scalars['ID']>;
  status: QuoteStatus;
};

export type FinalizeQuotePayload = {
  __typename?: 'FinalizeQuotePayload';
  quotation?: Maybe<Quotation>;
};

export enum Gender {
  Diverse = 'DIVERSE',
  Female = 'FEMALE',
  Male = 'MALE'
}

export type GenerateCheckInListInput = {
  eventId?: InputMaybe<Scalars['ID']>;
};

export type GenerateCheckInListPayload = {
  __typename?: 'GenerateCheckInListPayload';
  string?: Maybe<Scalars['String']>;
};

export type ImportEmployeeInput = {
  inputs: Array<CreateEmployeeInput>;
};

export type ImportEmployeePayload = {
  __typename?: 'ImportEmployeePayload';
  employee?: Maybe<Array<Employee>>;
};

export type InviteEmployeeInput = {
  employeeId?: InputMaybe<Scalars['ID']>;
};

export type InviteEmployeePayload = {
  __typename?: 'InviteEmployeePayload';
  employee?: Maybe<Employee>;
};

export type ListComparableByteOperationFilterInput = {
  all?: InputMaybe<ComparableByteOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ComparableByteOperationFilterInput>;
  some?: InputMaybe<ComparableByteOperationFilterInput>;
};

export type ListFilterInputTypeOfAccountFilterInput = {
  all?: InputMaybe<AccountFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AccountFilterInput>;
  some?: InputMaybe<AccountFilterInput>;
};

export type ListFilterInputTypeOfAccountPermissionFilterInput = {
  all?: InputMaybe<AccountPermissionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AccountPermissionFilterInput>;
  some?: InputMaybe<AccountPermissionFilterInput>;
};

export type ListFilterInputTypeOfBranchFilterInput = {
  all?: InputMaybe<BranchFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<BranchFilterInput>;
  some?: InputMaybe<BranchFilterInput>;
};

export type ListFilterInputTypeOfCustomQuoteItemFilterInput = {
  all?: InputMaybe<CustomQuoteItemFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CustomQuoteItemFilterInput>;
  some?: InputMaybe<CustomQuoteItemFilterInput>;
};

export type ListFilterInputTypeOfCustomerFilterInput = {
  all?: InputMaybe<CustomerFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CustomerFilterInput>;
  some?: InputMaybe<CustomerFilterInput>;
};

export type ListFilterInputTypeOfCustomerPriceFilterInput = {
  all?: InputMaybe<CustomerPriceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CustomerPriceFilterInput>;
  some?: InputMaybe<CustomerPriceFilterInput>;
};

export type ListFilterInputTypeOfCustomerRequestFilterInput = {
  all?: InputMaybe<CustomerRequestFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CustomerRequestFilterInput>;
  some?: InputMaybe<CustomerRequestFilterInput>;
};

export type ListFilterInputTypeOfDepartmentFilterInput = {
  all?: InputMaybe<DepartmentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DepartmentFilterInput>;
  some?: InputMaybe<DepartmentFilterInput>;
};

export type ListFilterInputTypeOfDeploymentFilterInput = {
  all?: InputMaybe<DeploymentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DeploymentFilterInput>;
  some?: InputMaybe<DeploymentFilterInput>;
};

export type ListFilterInputTypeOfEmployeeDocumentFilterInput = {
  all?: InputMaybe<EmployeeDocumentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<EmployeeDocumentFilterInput>;
  some?: InputMaybe<EmployeeDocumentFilterInput>;
};

export type ListFilterInputTypeOfEmployeeFilterInput = {
  all?: InputMaybe<EmployeeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<EmployeeFilterInput>;
  some?: InputMaybe<EmployeeFilterInput>;
};

export type ListFilterInputTypeOfEmployeeSkillModelFilterInput = {
  all?: InputMaybe<EmployeeSkillModelFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<EmployeeSkillModelFilterInput>;
  some?: InputMaybe<EmployeeSkillModelFilterInput>;
};

export type ListFilterInputTypeOfEmployeeTeamFilterInput = {
  all?: InputMaybe<EmployeeTeamFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<EmployeeTeamFilterInput>;
  some?: InputMaybe<EmployeeTeamFilterInput>;
};

export type ListFilterInputTypeOfEmploymentRelationModelFilterInput = {
  all?: InputMaybe<EmploymentRelationModelFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<EmploymentRelationModelFilterInput>;
  some?: InputMaybe<EmploymentRelationModelFilterInput>;
};

export type ListFilterInputTypeOfEventFilterInput = {
  all?: InputMaybe<EventFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<EventFilterInput>;
  some?: InputMaybe<EventFilterInput>;
};

export type ListFilterInputTypeOfOrderFilterInput = {
  all?: InputMaybe<OrderFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<OrderFilterInput>;
  some?: InputMaybe<OrderFilterInput>;
};

export type ListFilterInputTypeOfQuotationFilterInput = {
  all?: InputMaybe<QuotationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<QuotationFilterInput>;
  some?: InputMaybe<QuotationFilterInput>;
};

export type ListFilterInputTypeOfQuoteChatFilterInput = {
  all?: InputMaybe<QuoteChatFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<QuoteChatFilterInput>;
  some?: InputMaybe<QuoteChatFilterInput>;
};

export type ListFilterInputTypeOfQuoteItemFilterInput = {
  all?: InputMaybe<QuoteItemFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<QuoteItemFilterInput>;
  some?: InputMaybe<QuoteItemFilterInput>;
};

export type ListFilterInputTypeOfQuoteMessageFilterInput = {
  all?: InputMaybe<QuoteMessageFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<QuoteMessageFilterInput>;
  some?: InputMaybe<QuoteMessageFilterInput>;
};

export type ListFilterInputTypeOfShiftFilterInput = {
  all?: InputMaybe<ShiftFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ShiftFilterInput>;
  some?: InputMaybe<ShiftFilterInput>;
};

export type ListFilterInputTypeOfUserFilterInput = {
  all?: InputMaybe<UserFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<UserFilterInput>;
  some?: InputMaybe<UserFilterInput>;
};

export type ListFilterInputTypeOfUserRoleFilterInput = {
  all?: InputMaybe<UserRoleFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<UserRoleFilterInput>;
  some?: InputMaybe<UserRoleFilterInput>;
};

export type ListFilterInputTypeOfUserRolePermissionFilterInput = {
  all?: InputMaybe<UserRolePermissionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<UserRolePermissionFilterInput>;
  some?: InputMaybe<UserRolePermissionFilterInput>;
};

export type ListStringOperationFilterInput = {
  all?: InputMaybe<StringOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<StringOperationFilterInput>;
  some?: InputMaybe<StringOperationFilterInput>;
};

export type MarkCanceledShiftAsReadInput = {
  shiftIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type MarkCanceledShiftAsReadPayload = {
  __typename?: 'MarkCanceledShiftAsReadPayload';
  boolean?: Maybe<Scalars['Boolean']>;
};

export type MarkNotificationsAsReadInput = {
  ids: Array<Scalars['UUID']>;
};

export type MarkNotificationsAsReadPayload = {
  __typename?: 'MarkNotificationsAsReadPayload';
  string?: Maybe<Scalars['String']>;
};

export type MarkQuoteChatAsReadInput = {
  chatId?: InputMaybe<Scalars['ID']>;
};

export type MarkQuoteChatAsReadPayload = {
  __typename?: 'MarkQuoteChatAsReadPayload';
  quoteChat?: Maybe<QuoteChat>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptTermsAndConditions: AcceptTermsAndConditionsPayload;
  adminLogin: AdminLoginPayload;
  completeCheckInList: CompleteCheckInListPayload;
  confirmCheckInList: ConfirmCheckInListPayload;
  createAccount: CreateAccountPayload;
  createBranch: CreateBranchPayload;
  createCanceledShift: CreateCanceledShiftPayload;
  createCheckInMasters: CreateCheckInMastersPayload;
  createCustomer: CreateCustomerPayload;
  createCustomerRequest: CreateCustomerRequestPayload;
  createDeployment: CreateDeploymentPayload;
  createDocumentType: CreateDocumentTypePayload;
  createEmployee: CreateEmployeePayload;
  createEmployeeTeam: CreateEmployeeTeamPayload;
  createEvent: CreateEventPayload;
  createEventHelpRequest: CreateEventHelpRequestPayload;
  createEventLocation: CreateEventLocationPayload;
  createEventRequest: CreateEventRequestPayload;
  createEventTemplate: CreateEventTemplatePayload;
  createManyQuotes: CreateManyQuotesPayload;
  createPendingDispatchedShift: CreatePendingDispatchedShiftPayload;
  createQuote: CreateQuotePayload;
  createShifts: CreateShiftsPayload;
  createUser: CreateUserPayload;
  createUserRole: CreateUserRolePayload;
  createUserRolePermission: CreateUserRolePermissionPayload;
  deleteAccountSkills: DeleteAccountSkillsPayload;
  deleteDeployment: DeleteDeploymentPayload;
  deleteDocumentType: DeleteDocumentTypePayload;
  deleteEmployeeDocument: DeleteEmployeeDocumentPayload;
  deleteEmployeeTeam: DeleteEmployeeTeamPayload;
  deleteEventDocument: DeleteEventDocumentPayload;
  deleteEventRequest: DeleteEventRequestPayload;
  deletePendingDispatchedShift: DeletePendingDispatchedShiftPayload;
  employeeLogin: EmployeeLoginPayload;
  finalizeQuote: FinalizeQuotePayload;
  generateCheckInList: GenerateCheckInListPayload;
  importEmployee: ImportEmployeePayload;
  inviteEmployee: InviteEmployeePayload;
  markCanceledShiftAsRead: MarkCanceledShiftAsReadPayload;
  markNotificationsAsRead: MarkNotificationsAsReadPayload;
  markQuoteChatAsRead: MarkQuoteChatAsReadPayload;
  negotiateQuote: NegotiateQuotePayload;
  personioEmployees: PersonioEmployeesPayload;
  publishDeployments: PublishDeploymentsPayload;
  publishEvent: PublishEventPayload;
  publishEventTeams: PublishEventTeamsPayload;
  releaseCheckInList: ReleaseCheckInListPayload;
  requestContact: RequestContactPayload;
  requestPasswordReset: RequestPasswordResetPayload;
  revokeQuote: RevokeQuotePayload;
  savePendingDispatched: SavePendingDispatchedPayload;
  sendEventPushNotifications: SendEventPushNotificationsPayload;
  sendQuoteMessage: SendQuoteMessagePayload;
  setAccountSkills: SetAccountSkillsPayload;
  setCancelSettings: SetCancelSettingsPayload;
  setDeploymentTimes: SetDeploymentTimesPayload;
  setDeploymentTimesByEmployee: SetDeploymentTimesByEmployeePayload;
  setEmployeePassword: SetEmployeePasswordPayload;
  setEventInfoMessage: SetEventInfoMessagePayload;
  setManyDeploymentTimes: SetManyDeploymentTimesPayload;
  setPersonioTimes: SetPersonioTimesPayload;
  setPushToken: SetPushTokenPayload;
  setUserPassword: SetUserPasswordPayload;
  transmitCheckInList: TransmitCheckInListPayload;
  updateAccount: UpdateAccountPayload;
  updateAccountAddress: UpdateAccountAddressPayload;
  updateAccountStatus: UpdateAccountStatusPayload;
  updateBranch: UpdateBranchPayload;
  updateBranchStatus: UpdateBranchStatusPayload;
  updateCancelRequestStatus: UpdateCancelRequestStatusPayload;
  updateCustomerRequestStatus: UpdateCustomerRequestStatusPayload;
  updateDeployment: UpdateDeploymentPayload;
  updateDocumentType: UpdateDocumentTypePayload;
  updateEmployee: UpdateEmployeePayload;
  updateEmployeePassword: UpdateEmployeePasswordPayload;
  updateEmployeeStatus: UpdateEmployeeStatusPayload;
  updateEmployeeTeam: UpdateEmployeeTeamPayload;
  updateEvent: UpdateEventPayload;
  updateEventDocument: UpdateEventDocumentPayload;
  updateEventHelpRequest: UpdateEventHelpRequestPayload;
  updateEventLocation: UpdateEventLocationPayload;
  updateEventOrderType: UpdateEventOrderTypePayload;
  updateEventRequest: UpdateEventRequestPayload;
  updateEventStatus: UpdateEventStatusPayload;
  updateEventTemplate: UpdateEventTemplatePayload;
  updateMyAccount: UpdateMyAccountPayload;
  updateShift: UpdateShiftPayload;
  updateUser: UpdateUserPayload;
  updateUserPassword: UpdateUserPasswordPayload;
  updateUserRole: UpdateUserRolePayload;
  updateUserRoleStatus: UpdateUserRoleStatusPayload;
  updateUserStatus: UpdateUserStatusPayload;
  userLogin: UserLoginPayload;
};


export type MutationAdminLoginArgs = {
  input: AdminLoginInput;
};


export type MutationCompleteCheckInListArgs = {
  input: CompleteCheckInListInput;
};


export type MutationConfirmCheckInListArgs = {
  input: ConfirmCheckInListInput;
};


export type MutationCreateAccountArgs = {
  input: CreateAccountInput;
};


export type MutationCreateBranchArgs = {
  input: CreateBranchInput;
};


export type MutationCreateCanceledShiftArgs = {
  input: CreateCanceledShiftInput;
};


export type MutationCreateCheckInMastersArgs = {
  input: CreateCheckInMastersInput;
};


export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput;
};


export type MutationCreateCustomerRequestArgs = {
  input: CreateCustomerRequestInput;
};


export type MutationCreateDeploymentArgs = {
  input: CreateDeploymentInput;
};


export type MutationCreateDocumentTypeArgs = {
  input: CreateDocumentTypeInput;
};


export type MutationCreateEmployeeArgs = {
  input: CreateEmployeeInput;
};


export type MutationCreateEmployeeTeamArgs = {
  input: CreateEmployeeTeamInput;
};


export type MutationCreateEventArgs = {
  input: CreateEventInput;
};


export type MutationCreateEventHelpRequestArgs = {
  eventId?: InputMaybe<Scalars['ID']>;
  inputs: Array<CreateEventHelpRequestInput>;
};


export type MutationCreateEventLocationArgs = {
  input: CreateEventLocationInput;
};


export type MutationCreateEventRequestArgs = {
  input: CreateEventRequestInput;
};


export type MutationCreateEventTemplateArgs = {
  input: CreateEventTemplateInput;
};


export type MutationCreateManyQuotesArgs = {
  inputs: Array<CreateManyQuotesInput>;
};


export type MutationCreatePendingDispatchedShiftArgs = {
  input: CreatePendingDispatchedShiftInput;
};


export type MutationCreateQuoteArgs = {
  input: CreateQuoteInput;
};


export type MutationCreateShiftsArgs = {
  input: CreateShiftsInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationCreateUserRoleArgs = {
  input: CreateUserRoleInput;
};


export type MutationCreateUserRolePermissionArgs = {
  input: CreateUserRolePermissionInput;
};


export type MutationDeleteAccountSkillsArgs = {
  input: DeleteAccountSkillsInput;
};


export type MutationDeleteDeploymentArgs = {
  input: DeleteDeploymentInput;
};


export type MutationDeleteDocumentTypeArgs = {
  input: DeleteDocumentTypeInput;
};


export type MutationDeleteEmployeeDocumentArgs = {
  input: DeleteEmployeeDocumentInput;
};


export type MutationDeleteEmployeeTeamArgs = {
  input: DeleteEmployeeTeamInput;
};


export type MutationDeleteEventDocumentArgs = {
  input: DeleteEventDocumentInput;
};


export type MutationDeleteEventRequestArgs = {
  input: DeleteEventRequestInput;
};


export type MutationDeletePendingDispatchedShiftArgs = {
  input: DeletePendingDispatchedShiftInput;
};


export type MutationEmployeeLoginArgs = {
  input: EmployeeLoginInput;
};


export type MutationFinalizeQuoteArgs = {
  input: FinalizeQuoteInput;
};


export type MutationGenerateCheckInListArgs = {
  input: GenerateCheckInListInput;
};


export type MutationImportEmployeeArgs = {
  input: ImportEmployeeInput;
};


export type MutationInviteEmployeeArgs = {
  input: InviteEmployeeInput;
};


export type MutationMarkCanceledShiftAsReadArgs = {
  input: MarkCanceledShiftAsReadInput;
};


export type MutationMarkNotificationsAsReadArgs = {
  input: MarkNotificationsAsReadInput;
};


export type MutationMarkQuoteChatAsReadArgs = {
  input: MarkQuoteChatAsReadInput;
};


export type MutationNegotiateQuoteArgs = {
  input: NegotiateQuoteInput;
};


export type MutationPublishDeploymentsArgs = {
  input: PublishDeploymentsInput;
};


export type MutationPublishEventArgs = {
  input: PublishEventInput;
};


export type MutationPublishEventTeamsArgs = {
  input: PublishEventTeamsInput;
};


export type MutationReleaseCheckInListArgs = {
  input: ReleaseCheckInListInput;
};


export type MutationRequestContactArgs = {
  input: RequestContactInput;
};


export type MutationRequestPasswordResetArgs = {
  input: RequestPasswordResetInput;
};


export type MutationRevokeQuoteArgs = {
  input: RevokeQuoteInput;
};


export type MutationSavePendingDispatchedArgs = {
  input: SavePendingDispatchedInput;
};


export type MutationSendEventPushNotificationsArgs = {
  input: SendEventPushNotificationsInput;
};


export type MutationSendQuoteMessageArgs = {
  input: SendQuoteMessageInput;
};


export type MutationSetAccountSkillsArgs = {
  input: SetAccountSkillsInput;
};


export type MutationSetCancelSettingsArgs = {
  input: SetCancelSettingsInput;
};


export type MutationSetDeploymentTimesArgs = {
  input: SetDeploymentTimesInput;
};


export type MutationSetDeploymentTimesByEmployeeArgs = {
  input: SetDeploymentTimesByEmployeeInput;
};


export type MutationSetEmployeePasswordArgs = {
  input: SetEmployeePasswordInput;
};


export type MutationSetEventInfoMessageArgs = {
  input: SetEventInfoMessageInput;
};


export type MutationSetManyDeploymentTimesArgs = {
  input: SetManyDeploymentTimesInput;
};


export type MutationSetPersonioTimesArgs = {
  input: SetPersonioTimesInput;
};


export type MutationSetPushTokenArgs = {
  input: SetPushTokenInput;
};


export type MutationSetUserPasswordArgs = {
  input: SetUserPasswordInput;
};


export type MutationTransmitCheckInListArgs = {
  input: TransmitCheckInListInput;
};


export type MutationUpdateAccountArgs = {
  input: UpdateAccountInput;
};


export type MutationUpdateAccountAddressArgs = {
  input: UpdateAccountAddressInput;
};


export type MutationUpdateAccountStatusArgs = {
  input: UpdateAccountStatusInput;
};


export type MutationUpdateBranchArgs = {
  input: UpdateBranchInput;
};


export type MutationUpdateBranchStatusArgs = {
  input: UpdateBranchStatusInput;
};


export type MutationUpdateCancelRequestStatusArgs = {
  input: UpdateCancelRequestStatusInput;
};


export type MutationUpdateCustomerRequestStatusArgs = {
  input: UpdateCustomerRequestStatusInput;
};


export type MutationUpdateDeploymentArgs = {
  input: UpdateDeploymentInput;
};


export type MutationUpdateDocumentTypeArgs = {
  input: UpdateDocumentTypeInput;
};


export type MutationUpdateEmployeeArgs = {
  input: UpdateEmployeeInput;
};


export type MutationUpdateEmployeePasswordArgs = {
  input: UpdateEmployeePasswordInput;
};


export type MutationUpdateEmployeeStatusArgs = {
  input: UpdateEmployeeStatusInput;
};


export type MutationUpdateEmployeeTeamArgs = {
  input: UpdateEmployeeTeamInput;
};


export type MutationUpdateEventArgs = {
  input: UpdateEventInput;
};


export type MutationUpdateEventDocumentArgs = {
  input: UpdateEventDocumentInput;
};


export type MutationUpdateEventHelpRequestArgs = {
  input: UpdateEventHelpRequestInput;
};


export type MutationUpdateEventLocationArgs = {
  input: UpdateEventLocationInput;
};


export type MutationUpdateEventOrderTypeArgs = {
  input: UpdateEventOrderTypeInput;
};


export type MutationUpdateEventRequestArgs = {
  input: UpdateEventRequestInput;
};


export type MutationUpdateEventStatusArgs = {
  input: UpdateEventStatusInput;
};


export type MutationUpdateEventTemplateArgs = {
  input: UpdateEventTemplateInput;
};


export type MutationUpdateMyAccountArgs = {
  input: UpdateMyAccountInput;
};


export type MutationUpdateShiftArgs = {
  input: UpdateShiftInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateUserPasswordArgs = {
  input: UpdateUserPasswordInput;
};


export type MutationUpdateUserRoleArgs = {
  input: UpdateUserRoleInput;
};


export type MutationUpdateUserRoleStatusArgs = {
  input: UpdateUserRoleStatusInput;
};


export type MutationUpdateUserStatusArgs = {
  input: UpdateUserStatusInput;
};


export type MutationUserLoginArgs = {
  input: UserLoginInput;
};

export type MyCoventoDashboardModel = {
  __typename?: 'MyCoventoDashboardModel';
  branchCreated: Scalars['Boolean'];
  hasAdresss: Scalars['Boolean'];
  logoUploaded: Scalars['Boolean'];
  profileCompleted: Scalars['Boolean'];
  roleCreated: Scalars['Boolean'];
  termsAndCondtionsAccepted: Scalars['Boolean'];
  termsAndCondtionsUploaded: Scalars['Boolean'];
  user: User;
  userCreated: Scalars['Boolean'];
};

export type MyDeploymentsModel = {
  __typename?: 'MyDeploymentsModel';
  data: Array<Deployment>;
  title: Scalars['String'];
};

export type NavigationModel = {
  __typename?: 'NavigationModel';
  badge?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isTitle?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  link?: Maybe<Scalars['String']>;
  subItems?: Maybe<Array<NavigationModel>>;
};

export type NegotiateQuoteInput = {
  createCustomQuoteItemInputs?: InputMaybe<Array<CreateCustomQuoteItemInput>>;
  createQuoteItemInputs: Array<CreateQuoteItemInput>;
  message?: InputMaybe<Scalars['String']>;
  quoteId?: InputMaybe<Scalars['ID']>;
};

export type NegotiateQuotePayload = {
  __typename?: 'NegotiateQuotePayload';
  quotation?: Maybe<Quotation>;
};

export type Notification = {
  __typename?: 'Notification';
  account?: Maybe<Account>;
  color: Scalars['String'];
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  employee?: Maybe<Employee>;
  event?: Maybe<Event>;
  icon: Scalars['String'];
  id: Scalars['ID'];
  isRead: Scalars['Boolean'];
  link?: Maybe<Scalars['String']>;
  modifiedDate?: Maybe<Scalars['DateTime']>;
  target: NotificationTarget;
  text: Scalars['String'];
  title: Scalars['String'];
  updatedBy?: Maybe<User>;
  user?: Maybe<User>;
};

export type NotificationFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  and?: InputMaybe<Array<NotificationFilterInput>>;
  color?: InputMaybe<StringOperationFilterInput>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  employee?: InputMaybe<EmployeeFilterInput>;
  event?: InputMaybe<EventFilterInput>;
  icon?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  isRead?: InputMaybe<BooleanOperationFilterInput>;
  link?: InputMaybe<StringOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<NotificationFilterInput>>;
  target?: InputMaybe<NotificationTargetOperationFilterInput>;
  text?: InputMaybe<StringOperationFilterInput>;
  title?: InputMaybe<StringOperationFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
  user?: InputMaybe<UserFilterInput>;
};

export enum NotificationTarget {
  App = 'APP',
  MyCovento = 'MY_COVENTO',
  Planning = 'PLANNING',
  Platform = 'PLATFORM'
}

export type NotificationTargetOperationFilterInput = {
  eq?: InputMaybe<NotificationTarget>;
  in?: InputMaybe<Array<NotificationTarget>>;
  neq?: InputMaybe<NotificationTarget>;
  nin?: InputMaybe<Array<NotificationTarget>>;
};

export type NullableOfChatCloseReasonOperationFilterInput = {
  eq?: InputMaybe<ChatCloseReason>;
  in?: InputMaybe<Array<InputMaybe<ChatCloseReason>>>;
  neq?: InputMaybe<ChatCloseReason>;
  nin?: InputMaybe<Array<InputMaybe<ChatCloseReason>>>;
};

export type NullableOfEmploymentRelationshipOperationFilterInput = {
  eq?: InputMaybe<EmploymentRelationship>;
  in?: InputMaybe<Array<InputMaybe<EmploymentRelationship>>>;
  neq?: InputMaybe<EmploymentRelationship>;
  nin?: InputMaybe<Array<InputMaybe<EmploymentRelationship>>>;
};

export type NullableOfEventOrderTypeOperationFilterInput = {
  eq?: InputMaybe<EventOrderType>;
  in?: InputMaybe<Array<InputMaybe<EventOrderType>>>;
  neq?: InputMaybe<EventOrderType>;
  nin?: InputMaybe<Array<InputMaybe<EventOrderType>>>;
};

export type NullableOfGenderOperationFilterInput = {
  eq?: InputMaybe<Gender>;
  in?: InputMaybe<Array<InputMaybe<Gender>>>;
  neq?: InputMaybe<Gender>;
  nin?: InputMaybe<Array<InputMaybe<Gender>>>;
};

export type Order = {
  __typename?: 'Order';
  branch: Branch;
  branchId: Scalars['UUID'];
  cancelRequest?: Maybe<EventCancelRequest>;
  cancelRequestId?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  customItems: Array<CustomQuoteItem>;
  customer?: Maybe<Customer>;
  downloadUrl: Scalars['String'];
  event: Event;
  id: Scalars['ID'];
  items: Array<QuoteItem>;
  modifiedDate?: Maybe<Scalars['DateTime']>;
  net: Scalars['Float'];
  organizer: Organizer;
  quote: Quotation;
  quoteId: Scalars['UUID'];
  refNumber: Scalars['Int'];
  serviceProvider: ServiceProvider;
  status: OrderStatus;
  tax: Scalars['Float'];
  total: Scalars['Float'];
  updatedBy?: Maybe<User>;
  version: Scalars['Int'];
};

export type OrderFilterInput = {
  and?: InputMaybe<Array<OrderFilterInput>>;
  branch?: InputMaybe<BranchFilterInput>;
  branchId?: InputMaybe<ComparableGuidOperationFilterInput>;
  cancelRequest?: InputMaybe<EventCancelRequestFilterInput>;
  cancelRequestId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  customItems?: InputMaybe<ListFilterInputTypeOfCustomQuoteItemFilterInput>;
  customer?: InputMaybe<CustomerFilterInput>;
  downloadUrl?: InputMaybe<StringOperationFilterInput>;
  event?: InputMaybe<EventFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  items?: InputMaybe<ListFilterInputTypeOfQuoteItemFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  net?: InputMaybe<ComparableDoubleOperationFilterInput>;
  or?: InputMaybe<Array<OrderFilterInput>>;
  organizer?: InputMaybe<OrganizerFilterInput>;
  quote?: InputMaybe<QuotationFilterInput>;
  quoteId?: InputMaybe<ComparableGuidOperationFilterInput>;
  refNumber?: InputMaybe<ComparableInt32OperationFilterInput>;
  serviceProvider?: InputMaybe<ServiceProviderFilterInput>;
  status?: InputMaybe<OrderStatusOperationFilterInput>;
  tax?: InputMaybe<ComparableDoubleOperationFilterInput>;
  total?: InputMaybe<ComparableDoubleOperationFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
  version?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type OrderSortInput = {
  branch?: InputMaybe<BranchSortInput>;
  branchId?: InputMaybe<SortEnumType>;
  cancelRequest?: InputMaybe<EventCancelRequestSortInput>;
  cancelRequestId?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<UserSortInput>;
  creationTime?: InputMaybe<SortEnumType>;
  customer?: InputMaybe<CustomerSortInput>;
  downloadUrl?: InputMaybe<SortEnumType>;
  event?: InputMaybe<EventSortInput>;
  id?: InputMaybe<SortEnumType>;
  modifiedDate?: InputMaybe<SortEnumType>;
  net?: InputMaybe<SortEnumType>;
  organizer?: InputMaybe<OrganizerSortInput>;
  quote?: InputMaybe<QuotationSortInput>;
  quoteId?: InputMaybe<SortEnumType>;
  refNumber?: InputMaybe<SortEnumType>;
  serviceProvider?: InputMaybe<ServiceProviderSortInput>;
  status?: InputMaybe<SortEnumType>;
  tax?: InputMaybe<SortEnumType>;
  total?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<UserSortInput>;
  version?: InputMaybe<SortEnumType>;
};

export enum OrderStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Deprecated = 'DEPRECATED',
  Pending = 'PENDING'
}

export type OrderStatusOperationFilterInput = {
  eq?: InputMaybe<OrderStatus>;
  in?: InputMaybe<Array<OrderStatus>>;
  neq?: InputMaybe<OrderStatus>;
  nin?: InputMaybe<Array<OrderStatus>>;
};

export type Organizer = {
  __typename?: 'Organizer';
  aboutUs?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  branches: Array<Branch>;
  cancelSettings?: Maybe<CancelSettings>;
  customers: Array<Customer>;
  customersRequests: Array<CustomerRequest>;
  discriminator: Scalars['String'];
  email: Scalars['String'];
  employees: Array<Employee>;
  hrAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  permissions: Array<AccountPermission>;
  personioUser: Scalars['Boolean'];
  phone?: Maybe<Scalars['String']>;
  platformAccess: Scalars['Boolean'];
  readChats: Array<QuoteChat>;
  status: AccountStatus;
  termsAndConditionsAccepted: Scalars['Boolean'];
  termsAndConditionsUrl?: Maybe<Scalars['String']>;
  users: Array<User>;
  ventoWorkAccess: Scalars['Boolean'];
};

export type OrganizerFilterInput = {
  aboutUs?: InputMaybe<StringOperationFilterInput>;
  address?: InputMaybe<AddressFilterInput>;
  and?: InputMaybe<Array<OrganizerFilterInput>>;
  branches?: InputMaybe<ListFilterInputTypeOfBranchFilterInput>;
  cancelSettings?: InputMaybe<CancelSettingsFilterInput>;
  customers?: InputMaybe<ListFilterInputTypeOfCustomerFilterInput>;
  customersRequests?: InputMaybe<ListFilterInputTypeOfCustomerRequestFilterInput>;
  discriminator?: InputMaybe<StringOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  employees?: InputMaybe<ListFilterInputTypeOfEmployeeFilterInput>;
  hrAccess?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  logoUrl?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<OrganizerFilterInput>>;
  permissions?: InputMaybe<ListFilterInputTypeOfAccountPermissionFilterInput>;
  personioUser?: InputMaybe<BooleanOperationFilterInput>;
  phone?: InputMaybe<StringOperationFilterInput>;
  platformAccess?: InputMaybe<BooleanOperationFilterInput>;
  readChats?: InputMaybe<ListFilterInputTypeOfQuoteChatFilterInput>;
  status?: InputMaybe<AccountStatusOperationFilterInput>;
  termsAndConditionsAccepted?: InputMaybe<BooleanOperationFilterInput>;
  termsAndConditionsUrl?: InputMaybe<StringOperationFilterInput>;
  users?: InputMaybe<ListFilterInputTypeOfUserFilterInput>;
  ventoWorkAccess?: InputMaybe<BooleanOperationFilterInput>;
};

export type OrganizerSortInput = {
  aboutUs?: InputMaybe<SortEnumType>;
  address?: InputMaybe<AddressSortInput>;
  cancelSettings?: InputMaybe<CancelSettingsSortInput>;
  discriminator?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  hrAccess?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  logoUrl?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  personioUser?: InputMaybe<SortEnumType>;
  phone?: InputMaybe<SortEnumType>;
  platformAccess?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  termsAndConditionsAccepted?: InputMaybe<SortEnumType>;
  termsAndConditionsUrl?: InputMaybe<SortEnumType>;
  ventoWorkAccess?: InputMaybe<SortEnumType>;
};

export type PendingDispatchedShift = {
  __typename?: 'PendingDispatchedShift';
  account: Account;
  accountId: Scalars['ID'];
  branch?: Maybe<Branch>;
  branchId?: Maybe<Scalars['ID']>;
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  quantity: Scalars['Int'];
  shift: Shift;
  shiftId: Scalars['ID'];
};

export type PendingDispatchedShiftFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  accountId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  and?: InputMaybe<Array<PendingDispatchedShiftFilterInput>>;
  branch?: InputMaybe<BranchFilterInput>;
  branchId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  customer?: InputMaybe<CustomerFilterInput>;
  customerId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<PendingDispatchedShiftFilterInput>>;
  quantity?: InputMaybe<ComparableInt32OperationFilterInput>;
  shift?: InputMaybe<ShiftFilterInput>;
  shiftId?: InputMaybe<ComparableGuidOperationFilterInput>;
};

export type PersonioData = {
  __typename?: 'PersonioData';
  id: Array<Scalars['Int']>;
  message: Scalars['String'];
};

export type PersonioEmployeesPayload = {
  __typename?: 'PersonioEmployeesPayload';
  employee?: Maybe<Array<Employee>>;
};

export type PersonioProjectsAttributes = {
  __typename?: 'PersonioProjectsAttributes';
  active: Scalars['Boolean'];
  created_at: Scalars['DateTime'];
  name: Scalars['String'];
  updated_at: Scalars['DateTime'];
};

export type PersonioProjectsData = {
  __typename?: 'PersonioProjectsData';
  attributes: PersonioProjectsAttributes;
  id: Scalars['Int'];
  type: Scalars['String'];
};

export type PersonioProjectsRoot = {
  __typename?: 'PersonioProjectsRoot';
  data: Array<PersonioProjectsData>;
  success: Scalars['Boolean'];
};

export type PersonioResponse = {
  __typename?: 'PersonioResponse';
  data: PersonioData;
  success: Scalars['Boolean'];
};

export type PublishDeploymentsInput = {
  shiftId?: InputMaybe<Scalars['ID']>;
};

export type PublishDeploymentsPayload = {
  __typename?: 'PublishDeploymentsPayload';
  int?: Maybe<Scalars['Int']>;
};

export type PublishEventInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type PublishEventPayload = {
  __typename?: 'PublishEventPayload';
  event?: Maybe<Event>;
};

export type PublishEventTeamsInput = {
  eventId?: InputMaybe<Scalars['ID']>;
  publishedForAllEmployees: Scalars['Boolean'];
  teams?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type PublishEventTeamsPayload = {
  __typename?: 'PublishEventTeamsPayload';
  event?: Maybe<Event>;
};

export type Query = {
  __typename?: 'Query';
  account?: Maybe<Account>;
  accountSkills: Array<EmployeeSkillModel>;
  accounts: Array<Account>;
  appNotifications: Array<Notification>;
  assignmentProgress: EventAssignmentProgressModel;
  availableShifts: Array<AvailableShiftsModel>;
  branch?: Maybe<Branch>;
  branches: Array<Branch>;
  canceledShifts: Array<CanceledShift>;
  checkInDeployments: Array<Deployment>;
  checkInList: CheckInListModel;
  checkInMasters: Array<Event>;
  childShifts: Array<ChildShiftListModel>;
  completeEventData: EventCompleteDataModel;
  countAppNotifications: Scalars['Int'];
  countCustomerRequests: Scalars['Int'];
  countEventHelpRequests: Scalars['Int'];
  countEventRequests: Scalars['Int'];
  countNotifications: Scalars['Int'];
  countUnreadQuoteChats: Scalars['Int'];
  crmEmployees: Array<Employee>;
  crmUsers: Array<User>;
  customerRequests: Array<CustomerRequest>;
  customers: Array<Customer>;
  departments: Array<Department>;
  deployment?: Maybe<Deployment>;
  deploymentShifts: DeploymentsShiftModel;
  deployments: Array<Deployment>;
  deploymentsByMonth: Array<DeploymentByMonthModel>;
  documentTypes: Array<DocumentType>;
  employee?: Maybe<Employee>;
  employeeCheckInList: CheckInListModel;
  employeeDashboard: EmployeeDashboardModel;
  employeeDocuments: Array<EmployeeDocumentModel>;
  employeeEventRequests: Array<EventRequest>;
  employeeEvents: Array<Event>;
  employeeMe: Employee;
  employeeTeam?: Maybe<EmployeeTeam>;
  employeeTeams: Array<EmployeeTeam>;
  employees: Array<Employee>;
  event?: Maybe<Event>;
  eventDashboardStats: Array<EventDashboardStatsModel>;
  eventDocuments: Array<EventDocument>;
  eventEditShifts: ShiftEditModel;
  eventHelpRequests: Array<EventHelpRequest>;
  eventLocation?: Maybe<EventLocation>;
  eventLocations: Array<EventLocation>;
  eventRequests: Array<EventRequest>;
  eventTemplate?: Maybe<EventTemplate>;
  eventTemplates: Array<EventTemplate>;
  events: Array<Event>;
  isAvailableForAssignment: Scalars['Boolean'];
  manageShifts: Array<ShiftEditModel>;
  me: User;
  myBranches: Array<Branch>;
  myCoventoDashboard: MyCoventoDashboardModel;
  myDeployments: Array<MyDeploymentsModel>;
  myDocumentTypes: Array<DocumentType>;
  myEmployeeDocuments: Array<EmployeeDocumentModel>;
  myEvents: Array<Event>;
  navigations: Array<NavigationModel>;
  notifications: Array<Notification>;
  orders: Array<Order>;
  organizerChats: Array<QuoteChatModel>;
  organizers: Array<Organizer>;
  pendingDispatchedShifts: Array<PendingDispatchedShift>;
  personioProjects: PersonioProjectsRoot;
  quotation?: Maybe<Quotation>;
  quoteChat?: Maybe<QuoteChat>;
  quoteChats: Array<QuoteChat>;
  quotes: Array<Quotation>;
  serviceProvider?: Maybe<ServiceProvider>;
  serviceProviderChats: Array<QuoteChatModel>;
  serviceProviders: Array<ServiceProvider>;
  setCard: SetCardModel;
  shift?: Maybe<Shift>;
  shiftGroupedByFloors: Array<ShiftGroupByFloorModel>;
  shiftStatusQuery: Shift;
  shifts: Array<Shift>;
  user?: Maybe<User>;
  userRolePermissions: Array<UserRolePermission>;
  userRoles: Array<UserRole>;
  users: Array<User>;
};


export type QueryAccountArgs = {
  where?: InputMaybe<AccountFilterInput>;
};


export type QueryAppNotificationsArgs = {
  where?: InputMaybe<NotificationFilterInput>;
};


export type QueryAssignmentProgressArgs = {
  eventId: Scalars['ID'];
};


export type QueryAvailableShiftsArgs = {
  eventId: Scalars['ID'];
};


export type QueryBranchArgs = {
  where?: InputMaybe<BranchFilterInput>;
};


export type QueryCanceledShiftsArgs = {
  where?: InputMaybe<CanceledShiftFilterInput>;
};


export type QueryCheckInDeploymentsArgs = {
  where?: InputMaybe<DeploymentFilterInput>;
};


export type QueryCheckInListArgs = {
  eventId?: InputMaybe<Scalars['ID']>;
};


export type QueryChildShiftsArgs = {
  shiftId?: InputMaybe<Scalars['ID']>;
};


export type QueryCompleteEventDataArgs = {
  eventId?: InputMaybe<Scalars['ID']>;
};


export type QueryCountAppNotificationsArgs = {
  target: NotificationTarget;
};


export type QueryCountNotificationsArgs = {
  target: NotificationTarget;
};


export type QueryCountUnreadQuoteChatsArgs = {
  branchId?: InputMaybe<Scalars['ID']>;
  eventId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<QuoteChatStatus>;
};


export type QueryCrmEmployeesArgs = {
  where?: InputMaybe<EmployeeFilterInput>;
};


export type QueryCrmUsersArgs = {
  where?: InputMaybe<UserFilterInput>;
};


export type QueryCustomersArgs = {
  where?: InputMaybe<CustomerFilterInput>;
};


export type QueryDeploymentArgs = {
  where?: InputMaybe<DeploymentFilterInput>;
};


export type QueryDeploymentShiftsArgs = {
  shiftId?: InputMaybe<Scalars['ID']>;
};


export type QueryDeploymentsArgs = {
  order?: InputMaybe<Array<DeploymentSortInput>>;
  where?: InputMaybe<DeploymentFilterInput>;
};


export type QueryDeploymentsByMonthArgs = {
  employeeId: Scalars['ID'];
  year: Scalars['DateTime'];
};


export type QueryDocumentTypesArgs = {
  where?: InputMaybe<DocumentTypeFilterInput>;
};


export type QueryEmployeeArgs = {
  where?: InputMaybe<EmployeeFilterInput>;
};


export type QueryEmployeeCheckInListArgs = {
  eventId?: InputMaybe<Scalars['ID']>;
};


export type QueryEmployeeDocumentsArgs = {
  employeeId: Scalars['ID'];
};


export type QueryEmployeeEventRequestsArgs = {
  where?: InputMaybe<EventRequestFilterInput>;
};


export type QueryEmployeeTeamArgs = {
  where?: InputMaybe<EmployeeTeamFilterInput>;
};


export type QueryEmployeeTeamsArgs = {
  where?: InputMaybe<EmployeeTeamFilterInput>;
};


export type QueryEmployeesArgs = {
  where?: InputMaybe<EmployeeFilterInput>;
};


export type QueryEventArgs = {
  where?: InputMaybe<EventFilterInput>;
};


export type QueryEventDashboardStatsArgs = {
  eventId?: InputMaybe<Scalars['ID']>;
};


export type QueryEventDocumentsArgs = {
  where?: InputMaybe<EventDocumentFilterInput>;
};


export type QueryEventEditShiftsArgs = {
  eventId?: InputMaybe<Scalars['ID']>;
};


export type QueryEventHelpRequestsArgs = {
  where?: InputMaybe<EventHelpRequestFilterInput>;
};


export type QueryEventLocationArgs = {
  where?: InputMaybe<EventLocationFilterInput>;
};


export type QueryEventTemplateArgs = {
  where?: InputMaybe<EventTemplateFilterInput>;
};


export type QueryEventsArgs = {
  order?: InputMaybe<Array<EventSortInput>>;
  where?: InputMaybe<EventFilterInput>;
};


export type QueryIsAvailableForAssignmentArgs = {
  employeeId: Scalars['ID'];
  shiftId: Scalars['ID'];
};


export type QueryManageShiftsArgs = {
  eventId?: InputMaybe<Scalars['ID']>;
};


export type QueryMyBranchesArgs = {
  where?: InputMaybe<BranchFilterInput>;
};


export type QueryMyDeploymentsArgs = {
  isCompleted: Scalars['Boolean'];
};


export type QueryMyDocumentTypesArgs = {
  where?: InputMaybe<DocumentTypeFilterInput>;
};


export type QueryMyEventsArgs = {
  order?: InputMaybe<Array<EventSortInput>>;
  where?: InputMaybe<EventFilterInput>;
};


export type QueryNavigationsArgs = {
  target?: InputMaybe<NotificationTarget>;
};


export type QueryNotificationsArgs = {
  where?: InputMaybe<NotificationFilterInput>;
};


export type QueryOrdersArgs = {
  order?: InputMaybe<Array<OrderSortInput>>;
  where?: InputMaybe<OrderFilterInput>;
};


export type QueryOrganizerChatsArgs = {
  type: QuoteType;
};


export type QueryPendingDispatchedShiftsArgs = {
  where?: InputMaybe<PendingDispatchedShiftFilterInput>;
};


export type QueryQuotationArgs = {
  where?: InputMaybe<QuotationFilterInput>;
};


export type QueryQuoteChatArgs = {
  where?: InputMaybe<QuoteChatFilterInput>;
};


export type QueryQuoteChatsArgs = {
  where?: InputMaybe<QuoteChatFilterInput>;
};


export type QueryQuotesArgs = {
  order?: InputMaybe<Array<QuotationSortInput>>;
  where?: InputMaybe<QuotationFilterInput>;
};


export type QueryServiceProviderArgs = {
  where?: InputMaybe<ServiceProviderFilterInput>;
};


export type QueryServiceProviderChatsArgs = {
  type: QuoteType;
};


export type QuerySetCardArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryShiftArgs = {
  where?: InputMaybe<ShiftFilterInput>;
};


export type QueryShiftGroupedByFloorsArgs = {
  eventId: Scalars['ID'];
};


export type QueryShiftStatusQueryArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryShiftsArgs = {
  where?: InputMaybe<ShiftFilterInput>;
};


export type QueryUserArgs = {
  where?: InputMaybe<UserFilterInput>;
};

export type Quotation = {
  __typename?: 'Quotation';
  account: Account;
  branch: Branch;
  branchId: Scalars['UUID'];
  chat: QuoteChat;
  chatId: Scalars['ID'];
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  customItems: Array<CustomQuoteItem>;
  downloadUrl: Scalars['String'];
  event: Event;
  id: Scalars['ID'];
  items: Array<QuoteItem>;
  modifiedDate?: Maybe<Scalars['DateTime']>;
  net: Scalars['Float'];
  order?: Maybe<Order>;
  organizer: Organizer;
  refNumber: Scalars['Int'];
  serviceProvider: ServiceProvider;
  status: QuoteStatus;
  tax: Scalars['Float'];
  total: Scalars['Float'];
  type: QuoteType;
  updatedBy?: Maybe<User>;
  version: Scalars['Int'];
};

export type QuotationFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  and?: InputMaybe<Array<QuotationFilterInput>>;
  branch?: InputMaybe<BranchFilterInput>;
  branchId?: InputMaybe<ComparableGuidOperationFilterInput>;
  chat?: InputMaybe<QuoteChatFilterInput>;
  chatId?: InputMaybe<ComparableGuidOperationFilterInput>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  customItems?: InputMaybe<ListFilterInputTypeOfCustomQuoteItemFilterInput>;
  downloadUrl?: InputMaybe<StringOperationFilterInput>;
  event?: InputMaybe<EventFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  items?: InputMaybe<ListFilterInputTypeOfQuoteItemFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  net?: InputMaybe<ComparableDoubleOperationFilterInput>;
  or?: InputMaybe<Array<QuotationFilterInput>>;
  order?: InputMaybe<OrderFilterInput>;
  organizer?: InputMaybe<OrganizerFilterInput>;
  refNumber?: InputMaybe<ComparableInt32OperationFilterInput>;
  serviceProvider?: InputMaybe<ServiceProviderFilterInput>;
  status?: InputMaybe<QuoteStatusOperationFilterInput>;
  tax?: InputMaybe<ComparableDoubleOperationFilterInput>;
  total?: InputMaybe<ComparableDoubleOperationFilterInput>;
  type?: InputMaybe<QuoteTypeOperationFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
  version?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type QuotationSortInput = {
  account?: InputMaybe<AccountSortInput>;
  branch?: InputMaybe<BranchSortInput>;
  branchId?: InputMaybe<SortEnumType>;
  chat?: InputMaybe<QuoteChatSortInput>;
  chatId?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<UserSortInput>;
  creationTime?: InputMaybe<SortEnumType>;
  downloadUrl?: InputMaybe<SortEnumType>;
  event?: InputMaybe<EventSortInput>;
  id?: InputMaybe<SortEnumType>;
  modifiedDate?: InputMaybe<SortEnumType>;
  net?: InputMaybe<SortEnumType>;
  order?: InputMaybe<OrderSortInput>;
  organizer?: InputMaybe<OrganizerSortInput>;
  refNumber?: InputMaybe<SortEnumType>;
  serviceProvider?: InputMaybe<ServiceProviderSortInput>;
  status?: InputMaybe<SortEnumType>;
  tax?: InputMaybe<SortEnumType>;
  total?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<UserSortInput>;
  version?: InputMaybe<SortEnumType>;
};

export type QuoteChat = {
  __typename?: 'QuoteChat';
  branch: Branch;
  branchId: Scalars['UUID'];
  closeReason?: Maybe<ChatCloseReason>;
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  event: Event;
  eventId: Scalars['UUID'];
  id: Scalars['ID'];
  messages: Array<QuoteMessage>;
  modifiedDate?: Maybe<Scalars['DateTime']>;
  organizer: Organizer;
  quotes: Array<Quotation>;
  readBy: Array<Account>;
  serviceProvider: ServiceProvider;
  status: QuoteChatStatus;
  updatedBy?: Maybe<User>;
};

export type QuoteChatFilterInput = {
  and?: InputMaybe<Array<QuoteChatFilterInput>>;
  branch?: InputMaybe<BranchFilterInput>;
  branchId?: InputMaybe<ComparableGuidOperationFilterInput>;
  closeReason?: InputMaybe<NullableOfChatCloseReasonOperationFilterInput>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  event?: InputMaybe<EventFilterInput>;
  eventId?: InputMaybe<ComparableGuidOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  messages?: InputMaybe<ListFilterInputTypeOfQuoteMessageFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<QuoteChatFilterInput>>;
  organizer?: InputMaybe<OrganizerFilterInput>;
  quotes?: InputMaybe<ListFilterInputTypeOfQuotationFilterInput>;
  readBy?: InputMaybe<ListFilterInputTypeOfAccountFilterInput>;
  serviceProvider?: InputMaybe<ServiceProviderFilterInput>;
  status?: InputMaybe<QuoteChatStatusOperationFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export type QuoteChatModel = {
  __typename?: 'QuoteChatModel';
  id: Scalars['UUID'];
  name: Scalars['String'];
  quantity: Scalars['Int'];
};

export type QuoteChatSortInput = {
  branch?: InputMaybe<BranchSortInput>;
  branchId?: InputMaybe<SortEnumType>;
  closeReason?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<UserSortInput>;
  creationTime?: InputMaybe<SortEnumType>;
  event?: InputMaybe<EventSortInput>;
  eventId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  modifiedDate?: InputMaybe<SortEnumType>;
  organizer?: InputMaybe<OrganizerSortInput>;
  serviceProvider?: InputMaybe<ServiceProviderSortInput>;
  status?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<UserSortInput>;
};

export enum QuoteChatStatus {
  Accepted = 'ACCEPTED',
  Closed = 'CLOSED',
  Pending = 'PENDING'
}

export type QuoteChatStatusOperationFilterInput = {
  eq?: InputMaybe<QuoteChatStatus>;
  in?: InputMaybe<Array<QuoteChatStatus>>;
  neq?: InputMaybe<QuoteChatStatus>;
  nin?: InputMaybe<Array<QuoteChatStatus>>;
};

export type QuoteItem = {
  __typename?: 'QuoteItem';
  account: Account;
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  id: Scalars['ID'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  price: Scalars['Float'];
  quantity: Scalars['Int'];
  quotation: Quotation;
  shift: Shift;
  skill: EmployeeSkill;
  total: Scalars['Float'];
  updatedBy?: Maybe<User>;
};

export type QuoteItemFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  and?: InputMaybe<Array<QuoteItemFilterInput>>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<QuoteItemFilterInput>>;
  price?: InputMaybe<ComparableDoubleOperationFilterInput>;
  quantity?: InputMaybe<ComparableInt32OperationFilterInput>;
  quotation?: InputMaybe<QuotationFilterInput>;
  shift?: InputMaybe<ShiftFilterInput>;
  skill?: InputMaybe<EmployeeSkillOperationFilterInput>;
  total?: InputMaybe<ComparableDoubleOperationFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export type QuoteMessage = {
  __typename?: 'QuoteMessage';
  account: Account;
  chat: QuoteChat;
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  id: Scalars['ID'];
  message: Scalars['String'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  sendBy: User;
  updatedBy?: Maybe<User>;
};

export type QuoteMessageFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  and?: InputMaybe<Array<QuoteMessageFilterInput>>;
  chat?: InputMaybe<QuoteChatFilterInput>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  message?: InputMaybe<StringOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<QuoteMessageFilterInput>>;
  sendBy?: InputMaybe<UserFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export enum QuoteStatus {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Deprecated = 'DEPRECATED',
  Pending = 'PENDING',
  Revisioned = 'REVISIONED',
  Revoked = 'REVOKED'
}

export type QuoteStatusOperationFilterInput = {
  eq?: InputMaybe<QuoteStatus>;
  in?: InputMaybe<Array<QuoteStatus>>;
  neq?: InputMaybe<QuoteStatus>;
  nin?: InputMaybe<Array<QuoteStatus>>;
};

export enum QuoteType {
  Offer = 'OFFER',
  Request = 'REQUEST',
  Revision = 'REVISION'
}

export type QuoteTypeOperationFilterInput = {
  eq?: InputMaybe<QuoteType>;
  in?: InputMaybe<Array<QuoteType>>;
  neq?: InputMaybe<QuoteType>;
  nin?: InputMaybe<Array<QuoteType>>;
};

export type ReleaseCheckInListInput = {
  eventId?: InputMaybe<Scalars['ID']>;
};

export type ReleaseCheckInListPayload = {
  __typename?: 'ReleaseCheckInListPayload';
  event?: Maybe<Event>;
};

export type RequestContactInput = {
  input: SendEmailInput;
};

export type RequestContactPayload = {
  __typename?: 'RequestContactPayload';
  boolean?: Maybe<Scalars['Boolean']>;
};

export type RequestPasswordResetInput = {
  email: Scalars['String'];
  isUser: Scalars['Boolean'];
};

export type RequestPasswordResetPayload = {
  __typename?: 'RequestPasswordResetPayload';
  string?: Maybe<Scalars['String']>;
};

export type RevokeQuoteInput = {
  quoteId?: InputMaybe<Scalars['ID']>;
};

export type RevokeQuotePayload = {
  __typename?: 'RevokeQuotePayload';
  boolean?: Maybe<Scalars['Boolean']>;
};

export type SavePendingDispatchedInput = {
  eventId: Scalars['ID'];
};

export type SavePendingDispatchedPayload = {
  __typename?: 'SavePendingDispatchedPayload';
  string?: Maybe<Scalars['String']>;
};

export type SendEmailInput = {
  company: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  message: Scalars['String'];
};

export type SendEventPushNotificationsInput = {
  eventId?: InputMaybe<Scalars['ID']>;
  message: Scalars['String'];
};

export type SendEventPushNotificationsPayload = {
  __typename?: 'SendEventPushNotificationsPayload';
  int?: Maybe<Scalars['Int']>;
};

export type SendQuoteMessageInput = {
  chatId?: InputMaybe<Scalars['ID']>;
  message: Scalars['String'];
};

export type SendQuoteMessagePayload = {
  __typename?: 'SendQuoteMessagePayload';
  quoteMessage?: Maybe<QuoteMessage>;
};

export type ServiceProvider = {
  __typename?: 'ServiceProvider';
  aboutUs?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  branches: Array<Branch>;
  cancelSettings?: Maybe<CancelSettings>;
  customers: Array<Customer>;
  customersRequests: Array<CustomerRequest>;
  discriminator: Scalars['String'];
  email: Scalars['String'];
  employees: Array<Employee>;
  hrAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  permissions: Array<AccountPermission>;
  personioUser: Scalars['Boolean'];
  phone?: Maybe<Scalars['String']>;
  platformAccess: Scalars['Boolean'];
  readChats: Array<QuoteChat>;
  skills: Array<EmployeeSkillModel>;
  status: AccountStatus;
  termsAndConditionsAccepted: Scalars['Boolean'];
  termsAndConditionsUrl?: Maybe<Scalars['String']>;
  users: Array<User>;
  ventoWorkAccess: Scalars['Boolean'];
};

export type ServiceProviderFilterInput = {
  aboutUs?: InputMaybe<StringOperationFilterInput>;
  address?: InputMaybe<AddressFilterInput>;
  and?: InputMaybe<Array<ServiceProviderFilterInput>>;
  branches?: InputMaybe<ListFilterInputTypeOfBranchFilterInput>;
  cancelSettings?: InputMaybe<CancelSettingsFilterInput>;
  customers?: InputMaybe<ListFilterInputTypeOfCustomerFilterInput>;
  customersRequests?: InputMaybe<ListFilterInputTypeOfCustomerRequestFilterInput>;
  discriminator?: InputMaybe<StringOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  employees?: InputMaybe<ListFilterInputTypeOfEmployeeFilterInput>;
  hrAccess?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  logoUrl?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ServiceProviderFilterInput>>;
  permissions?: InputMaybe<ListFilterInputTypeOfAccountPermissionFilterInput>;
  personioUser?: InputMaybe<BooleanOperationFilterInput>;
  phone?: InputMaybe<StringOperationFilterInput>;
  platformAccess?: InputMaybe<BooleanOperationFilterInput>;
  readChats?: InputMaybe<ListFilterInputTypeOfQuoteChatFilterInput>;
  skills?: InputMaybe<ListFilterInputTypeOfEmployeeSkillModelFilterInput>;
  status?: InputMaybe<AccountStatusOperationFilterInput>;
  termsAndConditionsAccepted?: InputMaybe<BooleanOperationFilterInput>;
  termsAndConditionsUrl?: InputMaybe<StringOperationFilterInput>;
  users?: InputMaybe<ListFilterInputTypeOfUserFilterInput>;
  ventoWorkAccess?: InputMaybe<BooleanOperationFilterInput>;
};

export type ServiceProviderSortInput = {
  aboutUs?: InputMaybe<SortEnumType>;
  address?: InputMaybe<AddressSortInput>;
  cancelSettings?: InputMaybe<CancelSettingsSortInput>;
  discriminator?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  hrAccess?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  logoUrl?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  personioUser?: InputMaybe<SortEnumType>;
  phone?: InputMaybe<SortEnumType>;
  platformAccess?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  termsAndConditionsAccepted?: InputMaybe<SortEnumType>;
  termsAndConditionsUrl?: InputMaybe<SortEnumType>;
  ventoWorkAccess?: InputMaybe<SortEnumType>;
};

export type SetAccountSkillsInput = {
  skill: EmployeeSkill;
};

export type SetAccountSkillsPayload = {
  __typename?: 'SetAccountSkillsPayload';
  account?: Maybe<Account>;
};

export type SetCancelSettingsInput = {
  input: CreateCancelSettingsInput;
};

export type SetCancelSettingsPayload = {
  __typename?: 'SetCancelSettingsPayload';
  cancelSettings?: Maybe<CancelSettings>;
};

export type SetCardModel = {
  __typename?: 'SetCardModel';
  employee: Employee;
  next: Array<Deployment>;
  past: Array<Deployment>;
};

export type SetDeploymentTimesByEmployeeInput = {
  input: SetDeploymentTimesInput;
};

export type SetDeploymentTimesByEmployeePayload = {
  __typename?: 'SetDeploymentTimesByEmployeePayload';
  deployment?: Maybe<Deployment>;
};

export type SetDeploymentTimesInput = {
  breakTime?: InputMaybe<Scalars['Int']>;
  comment?: InputMaybe<Scalars['String']>;
  deploymentId?: InputMaybe<Scalars['ID']>;
  employeeStatus?: InputMaybe<DeploymentEmployeeStatus>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type SetDeploymentTimesPayload = {
  __typename?: 'SetDeploymentTimesPayload';
  deployment?: Maybe<Deployment>;
};

export type SetEmployeePasswordInput = {
  password: Scalars['String'];
  token: Scalars['String'];
  userId?: InputMaybe<Scalars['ID']>;
};

export type SetEmployeePasswordPayload = {
  __typename?: 'SetEmployeePasswordPayload';
  employee?: Maybe<Employee>;
};

export type SetEventInfoMessageInput = {
  eventId?: InputMaybe<Scalars['ID']>;
  message: Scalars['String'];
};

export type SetEventInfoMessagePayload = {
  __typename?: 'SetEventInfoMessagePayload';
  event?: Maybe<Event>;
};

export type SetManyDeploymentTimesInput = {
  inputs: Array<SetDeploymentTimesInput>;
};

export type SetManyDeploymentTimesPayload = {
  __typename?: 'SetManyDeploymentTimesPayload';
  string?: Maybe<Scalars['String']>;
};

export type SetPersonioTimesInput = {
  deploymentId: Scalars['UUID'];
};

export type SetPersonioTimesPayload = {
  __typename?: 'SetPersonioTimesPayload';
  personioResponse?: Maybe<PersonioResponse>;
};

export type SetPushTokenInput = {
  token: Scalars['String'];
};

export type SetPushTokenPayload = {
  __typename?: 'SetPushTokenPayload';
  employee?: Maybe<Employee>;
};

export type SetUserPasswordInput = {
  password: Scalars['String'];
  token: Scalars['String'];
  userId?: InputMaybe<Scalars['ID']>;
};

export type SetUserPasswordPayload = {
  __typename?: 'SetUserPasswordPayload';
  user?: Maybe<User>;
};

export type Shift = {
  __typename?: 'Shift';
  account?: Maybe<Account>;
  branch?: Maybe<Branch>;
  branchId?: Maybe<Scalars['ID']>;
  childShifts: Array<Shift>;
  createdBy?: Maybe<User>;
  creationTime: Scalars['DateTime'];
  currentQuantity: Scalars['Int'];
  deployments: Array<Deployment>;
  endDate: Scalars['DateTime'];
  event?: Maybe<Event>;
  eventId?: Maybe<Scalars['ID']>;
  eventTemplate?: Maybe<EventTemplate>;
  floorName?: Maybe<Scalars['String']>;
  hours: Scalars['Float'];
  id: Scalars['ID'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  parentEvent?: Maybe<Event>;
  parentEventId?: Maybe<Scalars['UUID']>;
  parentShift?: Maybe<Shift>;
  parentShiftId?: Maybe<Scalars['ID']>;
  preFilledEmployees: Array<Employee>;
  price?: Maybe<Scalars['Float']>;
  publishStatus: Scalars['String'];
  quoteItems: Array<QuoteItem>;
  requiredQuantity: Scalars['Int'];
  section?: Maybe<Scalars['String']>;
  shiftNumber: Scalars['Int'];
  skill: EmployeeSkill;
  startDate: Scalars['DateTime'];
  status: ShiftStatus;
  updatedBy?: Maybe<User>;
};


export type ShiftQuoteItemsArgs = {
  where?: InputMaybe<QuoteItemFilterInput>;
};

export enum ShiftDispatchType {
  Assigned = 'ASSIGNED',
  Employee = 'EMPLOYEE',
  Offer = 'OFFER',
  Request = 'REQUEST'
}

export type ShiftEditModel = {
  __typename?: 'ShiftEditModel';
  floor?: Maybe<Scalars['String']>;
  items: Array<ShiftItemModel>;
};

export type ShiftFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  and?: InputMaybe<Array<ShiftFilterInput>>;
  branch?: InputMaybe<BranchFilterInput>;
  branchId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  childShifts?: InputMaybe<ListFilterInputTypeOfShiftFilterInput>;
  createdBy?: InputMaybe<UserFilterInput>;
  creationTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  currentQuantity?: InputMaybe<ComparableInt32OperationFilterInput>;
  deployments?: InputMaybe<ListFilterInputTypeOfDeploymentFilterInput>;
  endDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  event?: InputMaybe<EventFilterInput>;
  eventId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  eventTemplate?: InputMaybe<EventTemplateFilterInput>;
  floorName?: InputMaybe<StringOperationFilterInput>;
  hours?: InputMaybe<ComparableDoubleOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ShiftFilterInput>>;
  parentEvent?: InputMaybe<EventFilterInput>;
  parentEventId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  parentShift?: InputMaybe<ShiftFilterInput>;
  parentShiftId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  preFilledEmployees?: InputMaybe<ListFilterInputTypeOfEmployeeFilterInput>;
  price?: InputMaybe<ComparableNullableOfDoubleOperationFilterInput>;
  publishStatus?: InputMaybe<StringOperationFilterInput>;
  quoteItems?: InputMaybe<ListFilterInputTypeOfQuoteItemFilterInput>;
  requiredQuantity?: InputMaybe<ComparableInt32OperationFilterInput>;
  section?: InputMaybe<StringOperationFilterInput>;
  shiftNumber?: InputMaybe<ComparableInt32OperationFilterInput>;
  skill?: InputMaybe<EmployeeSkillOperationFilterInput>;
  startDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  status?: InputMaybe<ShiftStatusOperationFilterInput>;
  updatedBy?: InputMaybe<UserFilterInput>;
};

export type ShiftGroupByFloorModel = {
  __typename?: 'ShiftGroupByFloorModel';
  floor: Scalars['String'];
  shifts: Array<Shift>;
};

export type ShiftItemModel = {
  __typename?: 'ShiftItemModel';
  childrens: Array<SingleShiftItemModel>;
  parent: Shift;
};

export type ShiftSortInput = {
  account?: InputMaybe<AccountSortInput>;
  branch?: InputMaybe<BranchSortInput>;
  branchId?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<UserSortInput>;
  creationTime?: InputMaybe<SortEnumType>;
  currentQuantity?: InputMaybe<SortEnumType>;
  endDate?: InputMaybe<SortEnumType>;
  event?: InputMaybe<EventSortInput>;
  eventId?: InputMaybe<SortEnumType>;
  eventTemplate?: InputMaybe<EventTemplateSortInput>;
  floorName?: InputMaybe<SortEnumType>;
  hours?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  modifiedDate?: InputMaybe<SortEnumType>;
  parentEvent?: InputMaybe<EventSortInput>;
  parentEventId?: InputMaybe<SortEnumType>;
  parentShift?: InputMaybe<ShiftSortInput>;
  parentShiftId?: InputMaybe<SortEnumType>;
  price?: InputMaybe<SortEnumType>;
  publishStatus?: InputMaybe<SortEnumType>;
  requiredQuantity?: InputMaybe<SortEnumType>;
  section?: InputMaybe<SortEnumType>;
  shiftNumber?: InputMaybe<SortEnumType>;
  skill?: InputMaybe<SortEnumType>;
  startDate?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  updatedBy?: InputMaybe<UserSortInput>;
};

export enum ShiftStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Pending = 'PENDING'
}

export type ShiftStatusOperationFilterInput = {
  eq?: InputMaybe<ShiftStatus>;
  in?: InputMaybe<Array<ShiftStatus>>;
  neq?: InputMaybe<ShiftStatus>;
  nin?: InputMaybe<Array<ShiftStatus>>;
};

export type SingleShiftItemModel = {
  __typename?: 'SingleShiftItemModel';
  accountsCount?: Maybe<Scalars['Int']>;
  branchesCount?: Maybe<Scalars['Int']>;
  customer?: Maybe<Customer>;
  employee?: Maybe<Employee>;
  endDate: Scalars['DateTime'];
  eventHelpRequest?: Maybe<Scalars['Int']>;
  floorName?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  quotesCount?: Maybe<Scalars['Int']>;
  section?: Maybe<Scalars['String']>;
  shiftId: Scalars['ID'];
  skill: EmployeeSkill;
  startDate: Scalars['DateTime'];
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ncontains?: InputMaybe<Scalars['String']>;
  nendsWith?: InputMaybe<Scalars['String']>;
  neq?: InputMaybe<Scalars['String']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nstartsWith?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type TransmitCheckInListInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type TransmitCheckInListPayload = {
  __typename?: 'TransmitCheckInListPayload';
  event?: Maybe<Event>;
};

export type UpdateAccountAddressInput = {
  input: CreateAddressInput;
};

export type UpdateAccountAddressPayload = {
  __typename?: 'UpdateAccountAddressPayload';
  account?: Maybe<Account>;
};

export type UpdateAccountInput = {
  email: Scalars['String'];
  hrAccess: Scalars['Boolean'];
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  permissionInputs: Array<CreateAccountPermissionInput>;
  platformAccess: Scalars['Boolean'];
  ventoWorkAccess: Scalars['Boolean'];
};

export type UpdateAccountPayload = {
  __typename?: 'UpdateAccountPayload';
  account?: Maybe<Account>;
};

export type UpdateAccountStatusInput = {
  accountId: Scalars['ID'];
  status: AccountStatus;
};

export type UpdateAccountStatusPayload = {
  __typename?: 'UpdateAccountStatusPayload';
  account?: Maybe<Account>;
};

export type UpdateBranchInput = {
  address?: InputMaybe<CreateAddressInput>;
  branchId?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};

export type UpdateBranchPayload = {
  __typename?: 'UpdateBranchPayload';
  branch?: Maybe<Branch>;
};

export type UpdateBranchStatusInput = {
  branchId: Scalars['ID'];
  isActive: Scalars['Boolean'];
};

export type UpdateBranchStatusPayload = {
  __typename?: 'UpdateBranchStatusPayload';
  branch?: Maybe<Branch>;
};

export type UpdateCancelRequestStatusInput = {
  requestId?: InputMaybe<Scalars['ID']>;
  status: QuoteStatus;
};

export type UpdateCancelRequestStatusPayload = {
  __typename?: 'UpdateCancelRequestStatusPayload';
  eventCancelRequest?: Maybe<EventCancelRequest>;
};

export type UpdateCustomerRequestStatusInput = {
  id?: InputMaybe<Scalars['ID']>;
  status: CustomerRequestStatus;
};

export type UpdateCustomerRequestStatusPayload = {
  __typename?: 'UpdateCustomerRequestStatusPayload';
  customerRequest?: Maybe<CustomerRequest>;
};

export type UpdateDeploymentInput = {
  deploymentId: Scalars['UUID'];
  shiftId: Scalars['UUID'];
};

export type UpdateDeploymentPayload = {
  __typename?: 'UpdateDeploymentPayload';
  deployment?: Maybe<Deployment>;
};

export type UpdateDocumentTypeInput = {
  hasExpiration: Scalars['Boolean'];
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  relationships: Array<EmploymentRelationship>;
};

export type UpdateDocumentTypePayload = {
  __typename?: 'UpdateDocumentTypePayload';
  documentType?: Maybe<DocumentType>;
};

export type UpdateEmployeeInput = {
  id?: InputMaybe<Scalars['ID']>;
  input: CreateEmployeeInput;
};

export type UpdateEmployeePasswordInput = {
  newPassword: Scalars['String'];
  password: Scalars['String'];
};

export type UpdateEmployeePasswordPayload = {
  __typename?: 'UpdateEmployeePasswordPayload';
  employee?: Maybe<Employee>;
};

export type UpdateEmployeePayload = {
  __typename?: 'UpdateEmployeePayload';
  employee?: Maybe<Employee>;
};

export type UpdateEmployeeStatusInput = {
  id?: InputMaybe<Scalars['ID']>;
  status: EmployeeStatus;
};

export type UpdateEmployeeStatusPayload = {
  __typename?: 'UpdateEmployeeStatusPayload';
  employee?: Maybe<Employee>;
};

export type UpdateEmployeeTeamInput = {
  branchId?: InputMaybe<Scalars['ID']>;
  employees?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name: Scalars['String'];
  teamId?: InputMaybe<Scalars['ID']>;
};

export type UpdateEmployeeTeamPayload = {
  __typename?: 'UpdateEmployeeTeamPayload';
  employeeTeam?: Maybe<EmployeeTeam>;
};

export type UpdateEventDocumentInput = {
  documentId: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateEventDocumentPayload = {
  __typename?: 'UpdateEventDocumentPayload';
  eventDocument?: Maybe<EventDocument>;
};

export type UpdateEventHelpRequestInput = {
  requestId?: InputMaybe<Scalars['ID']>;
  status: EventRequestStatus;
};

export type UpdateEventHelpRequestPayload = {
  __typename?: 'UpdateEventHelpRequestPayload';
  eventHelpRequest?: Maybe<EventHelpRequest>;
};

export type UpdateEventInput = {
  addressInput: CreateAddressInput;
  addressNotes?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  eventId?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  numberOfGuests: Scalars['Int'];
  personioProjectId?: InputMaybe<Scalars['Int']>;
  personioProjectName?: InputMaybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  type: EventType;
  withTemplate: Scalars['Boolean'];
};

export type UpdateEventLocationInput = {
  floors: Array<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type UpdateEventLocationPayload = {
  __typename?: 'UpdateEventLocationPayload';
  eventLocation?: Maybe<EventLocation>;
};

export type UpdateEventOrderTypeInput = {
  eventId?: InputMaybe<Scalars['ID']>;
  orderType: EventOrderType;
};

export type UpdateEventOrderTypePayload = {
  __typename?: 'UpdateEventOrderTypePayload';
  event?: Maybe<Event>;
};

export type UpdateEventPayload = {
  __typename?: 'UpdateEventPayload';
  event?: Maybe<Event>;
};

export type UpdateEventRequestInput = {
  requestId?: InputMaybe<Scalars['ID']>;
  status: EventRequestStatus;
};

export type UpdateEventRequestPayload = {
  __typename?: 'UpdateEventRequestPayload';
  eventRequest?: Maybe<EventRequest>;
};

export type UpdateEventStatusInput = {
  eventId?: InputMaybe<Scalars['ID']>;
  status: EventStatus;
};

export type UpdateEventStatusPayload = {
  __typename?: 'UpdateEventStatusPayload';
  updateEventStatusResponseModel?: Maybe<UpdateEventStatusResponseModel>;
};

export type UpdateEventStatusResponseModel = {
  __typename?: 'UpdateEventStatusResponseModel';
  errorsCount: Scalars['Int'];
  event: Event;
  successCount: Scalars['Int'];
};

export type UpdateEventTemplateInput = {
  input: CreateEventTemplateInput;
  templateId: Scalars['ID'];
};

export type UpdateEventTemplatePayload = {
  __typename?: 'UpdateEventTemplatePayload';
  eventTemplate?: Maybe<EventTemplate>;
};

export type UpdateMyAccountInput = {
  aboutUs?: InputMaybe<Scalars['String']>;
  address: CreateAddressInput;
  email: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};

export type UpdateMyAccountPayload = {
  __typename?: 'UpdateMyAccountPayload';
  account?: Maybe<Account>;
};

export type UpdateShiftInput = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  requiredQuantity: Scalars['Int'];
  section?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateShiftPayload = {
  __typename?: 'UpdateShiftPayload';
  shift?: Maybe<Shift>;
};

export type UpdateUserInput = {
  branchIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  firstName: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  lastName: Scalars['String'];
  role: Scalars['ID'];
};

export type UpdateUserPasswordInput = {
  newPassword: Scalars['String'];
  password: Scalars['String'];
};

export type UpdateUserPasswordPayload = {
  __typename?: 'UpdateUserPasswordPayload';
  user?: Maybe<User>;
};

export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  user?: Maybe<User>;
};

export type UpdateUserRoleInput = {
  name: Scalars['String'];
  permissionIds?: InputMaybe<Array<Scalars['ID']>>;
  roleId: Scalars['ID'];
};

export type UpdateUserRolePayload = {
  __typename?: 'UpdateUserRolePayload';
  userRole?: Maybe<UserRole>;
};

export type UpdateUserRoleStatusInput = {
  isActive: Scalars['Boolean'];
  roleId?: InputMaybe<Scalars['ID']>;
};

export type UpdateUserRoleStatusPayload = {
  __typename?: 'UpdateUserRoleStatusPayload';
  userRole?: Maybe<UserRole>;
};

export type UpdateUserStatusInput = {
  status: AccountStatus;
  userId?: InputMaybe<Scalars['ID']>;
};

export type UpdateUserStatusPayload = {
  __typename?: 'UpdateUserStatusPayload';
  user?: Maybe<User>;
};

export type User = {
  __typename?: 'User';
  account: Account;
  branches: Array<Branch>;
  credentials: Credentials;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  marketPlaceAccess: Scalars['Boolean'];
  myCoventoAccess: Scalars['Boolean'];
  passwordResetCode?: Maybe<Array<Scalars['Byte']>>;
  passwordResetStart?: Maybe<Scalars['DateTime']>;
  planningAccess: Scalars['Boolean'];
  role?: Maybe<UserRole>;
  roleId?: Maybe<Scalars['UUID']>;
  status: AccountStatus;
};

export type UserFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  and?: InputMaybe<Array<UserFilterInput>>;
  branches?: InputMaybe<ListFilterInputTypeOfBranchFilterInput>;
  credentials?: InputMaybe<CredentialsFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  lastName?: InputMaybe<StringOperationFilterInput>;
  marketPlaceAccess?: InputMaybe<BooleanOperationFilterInput>;
  myCoventoAccess?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<UserFilterInput>>;
  passwordResetCode?: InputMaybe<ListComparableByteOperationFilterInput>;
  passwordResetStart?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  planningAccess?: InputMaybe<BooleanOperationFilterInput>;
  role?: InputMaybe<UserRoleFilterInput>;
  roleId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  status?: InputMaybe<AccountStatusOperationFilterInput>;
};

export type UserLoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type UserLoginPayload = {
  __typename?: 'UserLoginPayload';
  string?: Maybe<Scalars['String']>;
};

export type UserRole = {
  __typename?: 'UserRole';
  account: Account;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  permissions: Array<UserRolePermission>;
  users: Array<User>;
};

export type UserRoleFilterInput = {
  account?: InputMaybe<AccountFilterInput>;
  and?: InputMaybe<Array<UserRoleFilterInput>>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<UserRoleFilterInput>>;
  permissions?: InputMaybe<ListFilterInputTypeOfUserRolePermissionFilterInput>;
  users?: InputMaybe<ListFilterInputTypeOfUserFilterInput>;
};

export type UserRolePermission = {
  __typename?: 'UserRolePermission';
  description: Scalars['String'];
  id: Scalars['ID'];
  product: NotificationTarget;
  roles: Array<UserRole>;
  scope: UserRolePermissionScope;
  target: UserRolePermissionTarget;
};

export type UserRolePermissionFilterInput = {
  and?: InputMaybe<Array<UserRolePermissionFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<UserRolePermissionFilterInput>>;
  product?: InputMaybe<NotificationTargetOperationFilterInput>;
  roles?: InputMaybe<ListFilterInputTypeOfUserRoleFilterInput>;
  scope?: InputMaybe<UserRolePermissionScopeOperationFilterInput>;
  target?: InputMaybe<UserRolePermissionTargetOperationFilterInput>;
};

export enum UserRolePermissionScope {
  Create = 'CREATE',
  Delete = 'DELETE',
  Edit = 'EDIT',
  Read = 'READ',
  UpdateStatus = 'UPDATE_STATUS'
}

export type UserRolePermissionScopeOperationFilterInput = {
  eq?: InputMaybe<UserRolePermissionScope>;
  in?: InputMaybe<Array<UserRolePermissionScope>>;
  neq?: InputMaybe<UserRolePermissionScope>;
  nin?: InputMaybe<Array<UserRolePermissionScope>>;
};

export enum UserRolePermissionTarget {
  Account = 'ACCOUNT',
  Branch = 'BRANCH',
  CheckInList = 'CHECK_IN_LIST',
  Customer = 'CUSTOMER',
  CustomerRequest = 'CUSTOMER_REQUEST',
  Department = 'DEPARTMENT',
  Deployment = 'DEPLOYMENT',
  DocumentType = 'DOCUMENT_TYPE',
  Employee = 'EMPLOYEE',
  EmployeeRequest = 'EMPLOYEE_REQUEST',
  EmployeeTeam = 'EMPLOYEE_TEAM',
  Event = 'EVENT',
  EventHelpRequest = 'EVENT_HELP_REQUEST',
  EventLocation = 'EVENT_LOCATION',
  Quote = 'QUOTE',
  Shift = 'SHIFT',
  User = 'USER',
  UserRole = 'USER_ROLE'
}

export type UserRolePermissionTargetOperationFilterInput = {
  eq?: InputMaybe<UserRolePermissionTarget>;
  in?: InputMaybe<Array<UserRolePermissionTarget>>;
  neq?: InputMaybe<UserRolePermissionTarget>;
  nin?: InputMaybe<Array<UserRolePermissionTarget>>;
};

export type UserRoleSortInput = {
  account?: InputMaybe<AccountSortInput>;
  id?: InputMaybe<SortEnumType>;
  isActive?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type UserSortInput = {
  account?: InputMaybe<AccountSortInput>;
  credentials?: InputMaybe<CredentialsSortInput>;
  email?: InputMaybe<SortEnumType>;
  firstName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastName?: InputMaybe<SortEnumType>;
  marketPlaceAccess?: InputMaybe<SortEnumType>;
  myCoventoAccess?: InputMaybe<SortEnumType>;
  passwordResetStart?: InputMaybe<SortEnumType>;
  planningAccess?: InputMaybe<SortEnumType>;
  role?: InputMaybe<UserRoleSortInput>;
  roleId?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
};

export type AccountQueryVariables = Exact<{
  where?: InputMaybe<AccountFilterInput>;
}>;


export type AccountQuery = { __typename?: 'Query', account?: { __typename?: 'Account', id: string, name: string, platformAccess: boolean, ventoWorkAccess: boolean, hrAccess: boolean, email: string, permissions: Array<{ __typename?: 'AccountPermission', id: string, scope: AccountPermissionScope }> } | null };

export type AccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountsQuery = { __typename?: 'Query', accounts: Array<{ __typename?: 'Account', id: string, status: AccountStatus, name: string, email: string, discriminator: string, platformAccess: boolean, ventoWorkAccess: boolean, hrAccess: boolean, permissions: Array<{ __typename?: 'AccountPermission', id: string, scope: AccountPermissionScope, target: NotificationTarget }>, employees: Array<{ __typename?: 'Employee', id: string, status: EmployeeStatus }>, users: Array<{ __typename?: 'User', id: string }> }> };

export type CreateAccountMutationVariables = Exact<{
  input: CreateAccountInput;
}>;


export type CreateAccountMutation = { __typename?: 'Mutation', createAccount: { __typename?: 'CreateAccountPayload', account?: { __typename?: 'Account', id: string } | null } };

export type UpdateAccountMutationVariables = Exact<{
  input: UpdateAccountInput;
}>;


export type UpdateAccountMutation = { __typename?: 'Mutation', updateAccount: { __typename?: 'UpdateAccountPayload', account?: { __typename?: 'Account', id: string } | null } };

export type UpdateAccountStatusMutationVariables = Exact<{
  input: UpdateAccountStatusInput;
}>;


export type UpdateAccountStatusMutation = { __typename?: 'Mutation', updateAccountStatus: { __typename?: 'UpdateAccountStatusPayload', account?: { __typename?: 'Account', id: string } | null } };

export type AdminLoginMutationVariables = Exact<{
  input: AdminLoginInput;
}>;


export type AdminLoginMutation = { __typename?: 'Mutation', adminLogin: { __typename?: 'AdminLoginPayload', string?: string | null } };

export type LoginMutationVariables = Exact<{
  input: UserLoginInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', userLogin: { __typename?: 'UserLoginPayload', string?: string | null } };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'User', id: string, firstName: string, lastName: string, email: string, account: { __typename?: 'Account', id: string, discriminator: string, logoUrl?: string | null, termsAndConditionsUrl?: string | null, phone?: string | null, email: string, aboutUs?: string | null, address?: { __typename?: 'Address', id: string, street?: string | null, streetNumber?: number | null, city?: string | null, zip?: string | null, formattedAddress?: string | null, latitude?: number | null, longitude?: number | null } | null } } };

export type BranchQueryVariables = Exact<{
  where?: InputMaybe<BranchFilterInput>;
}>;


export type BranchQuery = { __typename?: 'Query', branch?: { __typename?: 'Branch', id: string, name: string, email?: string | null, phone?: string | null } | null };

export type BranchesQueryVariables = Exact<{ [key: string]: never; }>;


export type BranchesQuery = { __typename?: 'Query', branches: Array<{ __typename?: 'Branch', id: string, name: string, isActive: boolean, email?: string | null, phone?: string | null, address?: { __typename?: 'Address', id: string, street?: string | null, streetNumber?: number | null, city?: string | null, zip?: string | null } | null }> };

export type CreateBranchMutationVariables = Exact<{
  input: CreateBranchInput;
}>;


export type CreateBranchMutation = { __typename?: 'Mutation', createBranch: { __typename?: 'CreateBranchPayload', branch?: { __typename?: 'Branch', id: string } | null } };

export type UpdateBranchMutationVariables = Exact<{
  data: UpdateBranchInput;
}>;


export type UpdateBranchMutation = { __typename?: 'Mutation', updateBranch: { __typename?: 'UpdateBranchPayload', branch?: { __typename?: 'Branch', id: string } | null } };

export type AcceptTermsAndConditionsMutationVariables = Exact<{ [key: string]: never; }>;


export type AcceptTermsAndConditionsMutation = { __typename?: 'Mutation', acceptTermsAndConditions: { __typename?: 'AcceptTermsAndConditionsPayload', account?: { __typename?: 'Account', id: string } | null } };

export type MyCoventoDashboardQueryVariables = Exact<{ [key: string]: never; }>;


export type MyCoventoDashboardQuery = { __typename?: 'Query', myCoventoDashboard: { __typename?: 'MyCoventoDashboardModel', profileCompleted: boolean, roleCreated: boolean, userCreated: boolean, branchCreated: boolean, hasAdresss: boolean, termsAndCondtionsAccepted: boolean, termsAndCondtionsUploaded: boolean, logoUploaded: boolean, user: { __typename?: 'User', id: string, firstName: string } } };

export type UpdateAccountAddressMutationVariables = Exact<{
  input: UpdateAccountAddressInput;
}>;


export type UpdateAccountAddressMutation = { __typename?: 'Mutation', updateAccountAddress: { __typename?: 'UpdateAccountAddressPayload', account?: { __typename?: 'Account', id: string } | null } };

export type UpdateAddressMutationVariables = Exact<{
  input: UpdateAccountAddressInput;
}>;


export type UpdateAddressMutation = { __typename?: 'Mutation', updateAccountAddress: { __typename?: 'UpdateAccountAddressPayload', account?: { __typename?: 'Account', id: string } | null } };

export type DepartmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type DepartmentsQuery = { __typename?: 'Query', departments: Array<{ __typename?: 'Department', id: string, name: string, branch: { __typename?: 'Branch', id: string, name: string } }> };

export type EmployeesQueryVariables = Exact<{
  where?: InputMaybe<EmployeeFilterInput>;
}>;


export type EmployeesQuery = { __typename?: 'Query', crmEmployees: Array<{ __typename?: 'Employee', id: string, email: string, status: EmployeeStatus }> };

export type NavsQueryVariables = Exact<{ [key: string]: never; }>;


export type NavsQuery = { __typename?: 'Query', navigations: Array<{ __typename?: 'NavigationModel', id: string, icon?: string | null, link?: string | null, label: string, badge?: string | null, subItems?: Array<{ __typename?: 'NavigationModel', id: string, label: string, icon?: string | null, link?: string | null, badge?: string | null }> | null }> };

export type MarkNotificationsAsReadMutationVariables = Exact<{
  input: MarkNotificationsAsReadInput;
}>;


export type MarkNotificationsAsReadMutation = { __typename?: 'Mutation', markNotificationsAsRead: { __typename?: 'MarkNotificationsAsReadPayload', string?: string | null } };

export type NotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type NotificationsQuery = { __typename?: 'Query', notifications: Array<{ __typename?: 'Notification', id: string, text: string, title: string, icon: string, color: string, link?: string | null, creationTime: any }> };

export type CreateUserPermissionMutationVariables = Exact<{
  input: CreateUserRolePermissionInput;
}>;


export type CreateUserPermissionMutation = { __typename?: 'Mutation', createUserRolePermission: { __typename?: 'CreateUserRolePermissionPayload', userRolePermission?: { __typename?: 'UserRolePermission', id: string } | null } };

export type UserPermissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserPermissionsQuery = { __typename?: 'Query', userRolePermissions: Array<{ __typename?: 'UserRolePermission', id: string, description: string, product: NotificationTarget, scope: UserRolePermissionScope, target: UserRolePermissionTarget }> };

export type UpdateMyAccountMutationVariables = Exact<{
  input: UpdateMyAccountInput;
}>;


export type UpdateMyAccountMutation = { __typename?: 'Mutation', updateMyAccount: { __typename?: 'UpdateMyAccountPayload', account?: { __typename?: 'Account', id: string } | null } };

export type UsersQueryVariables = Exact<{
  where?: InputMaybe<UserFilterInput>;
}>;


export type UsersQuery = { __typename?: 'Query', crmUsers: Array<{ __typename?: 'User', id: string, email: string, status: AccountStatus }> };

export const AccountDocument = gql`
    query account($where: AccountFilterInput) {
  account(where: $where) {
    id
    name
    platformAccess
    ventoWorkAccess
    hrAccess
    email
    permissions {
      id
      scope
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AccountGQL extends Apollo.Query<AccountQuery, AccountQueryVariables> {
    override document = AccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AccountsDocument = gql`
    query accounts {
  accounts {
    id
    status
    name
    email
    discriminator
    platformAccess
    ventoWorkAccess
    hrAccess
    permissions {
      id
      scope
      target
    }
    employees {
      id
      status
    }
    users {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AccountsGQL extends Apollo.Query<AccountsQuery, AccountsQueryVariables> {
    override document = AccountsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateAccountDocument = gql`
    mutation createAccount($input: CreateAccountInput!) {
  createAccount(input: $input) {
    account {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateAccountGQL extends Apollo.Mutation<CreateAccountMutation, CreateAccountMutationVariables> {
    override document = CreateAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAccountDocument = gql`
    mutation updateAccount($input: UpdateAccountInput!) {
  updateAccount(input: $input) {
    account {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAccountGQL extends Apollo.Mutation<UpdateAccountMutation, UpdateAccountMutationVariables> {
    override document = UpdateAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAccountStatusDocument = gql`
    mutation updateAccountStatus($input: UpdateAccountStatusInput!) {
  updateAccountStatus(input: $input) {
    account {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAccountStatusGQL extends Apollo.Mutation<UpdateAccountStatusMutation, UpdateAccountStatusMutationVariables> {
    override document = UpdateAccountStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AdminLoginDocument = gql`
    mutation adminLogin($input: AdminLoginInput!) {
  adminLogin(input: $input) {
    string
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AdminLoginGQL extends Apollo.Mutation<AdminLoginMutation, AdminLoginMutationVariables> {
    override document = AdminLoginDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoginDocument = gql`
    mutation login($input: UserLoginInput!) {
  userLogin(input: $input) {
    string
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginGQL extends Apollo.Mutation<LoginMutation, LoginMutationVariables> {
    override document = LoginDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MeDocument = gql`
    query me {
  me {
    id
    firstName
    lastName
    email
    account {
      id
      discriminator
      logoUrl
      termsAndConditionsUrl
      phone
      email
      aboutUs
      address {
        id
        street
        streetNumber
        city
        zip
        formattedAddress
        latitude
        longitude
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MeGQL extends Apollo.Query<MeQuery, MeQueryVariables> {
    override document = MeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BranchDocument = gql`
    query branch($where: BranchFilterInput) {
  branch(where: $where) {
    id
    name
    email
    phone
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BranchGQL extends Apollo.Query<BranchQuery, BranchQueryVariables> {
    override document = BranchDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BranchesDocument = gql`
    query branches {
  branches {
    id
    name
    isActive
    address {
      id
      street
      streetNumber
      city
      zip
    }
    email
    phone
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BranchesGQL extends Apollo.Query<BranchesQuery, BranchesQueryVariables> {
    override document = BranchesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateBranchDocument = gql`
    mutation createBranch($input: CreateBranchInput!) {
  createBranch(input: $input) {
    branch {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateBranchGQL extends Apollo.Mutation<CreateBranchMutation, CreateBranchMutationVariables> {
    override document = CreateBranchDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateBranchDocument = gql`
    mutation updateBranch($data: UpdateBranchInput!) {
  updateBranch(input: $data) {
    branch {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBranchGQL extends Apollo.Mutation<UpdateBranchMutation, UpdateBranchMutationVariables> {
    override document = UpdateBranchDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AcceptTermsAndConditionsDocument = gql`
    mutation acceptTermsAndConditions {
  acceptTermsAndConditions {
    account {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptTermsAndConditionsGQL extends Apollo.Mutation<AcceptTermsAndConditionsMutation, AcceptTermsAndConditionsMutationVariables> {
    override document = AcceptTermsAndConditionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MyCoventoDashboardDocument = gql`
    query myCoventoDashboard {
  myCoventoDashboard {
    profileCompleted
    roleCreated
    userCreated
    branchCreated
    hasAdresss
    termsAndCondtionsAccepted
    termsAndCondtionsUploaded
    logoUploaded
    user {
      id
      firstName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MyCoventoDashboardGQL extends Apollo.Query<MyCoventoDashboardQuery, MyCoventoDashboardQueryVariables> {
    override document = MyCoventoDashboardDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAccountAddressDocument = gql`
    mutation updateAccountAddress($input: UpdateAccountAddressInput!) {
  updateAccountAddress(input: $input) {
    account {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAccountAddressGQL extends Apollo.Mutation<UpdateAccountAddressMutation, UpdateAccountAddressMutationVariables> {
    override document = UpdateAccountAddressDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAddressDocument = gql`
    mutation updateAddress($input: UpdateAccountAddressInput!) {
  updateAccountAddress(input: $input) {
    account {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAddressGQL extends Apollo.Mutation<UpdateAddressMutation, UpdateAddressMutationVariables> {
    override document = UpdateAddressDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DepartmentsDocument = gql`
    query departments {
  departments {
    id
    name
    branch {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DepartmentsGQL extends Apollo.Query<DepartmentsQuery, DepartmentsQueryVariables> {
    override document = DepartmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EmployeesDocument = gql`
    query employees($where: EmployeeFilterInput) {
  crmEmployees(where: $where) {
    id
    email
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EmployeesGQL extends Apollo.Query<EmployeesQuery, EmployeesQueryVariables> {
    override document = EmployeesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NavsDocument = gql`
    query navs {
  navigations {
    id
    icon
    link
    label
    badge
    subItems {
      id
      label
      icon
      link
      badge
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class NavsGQL extends Apollo.Query<NavsQuery, NavsQueryVariables> {
    override document = NavsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MarkNotificationsAsReadDocument = gql`
    mutation markNotificationsAsRead($input: MarkNotificationsAsReadInput!) {
  markNotificationsAsRead(input: $input) {
    string
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MarkNotificationsAsReadGQL extends Apollo.Mutation<MarkNotificationsAsReadMutation, MarkNotificationsAsReadMutationVariables> {
    override document = MarkNotificationsAsReadDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NotificationsDocument = gql`
    query notifications {
  notifications {
    id
    text
    title
    icon
    color
    link
    creationTime
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class NotificationsGQL extends Apollo.Query<NotificationsQuery, NotificationsQueryVariables> {
    override document = NotificationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateUserPermissionDocument = gql`
    mutation createUserPermission($input: CreateUserRolePermissionInput!) {
  createUserRolePermission(input: $input) {
    userRolePermission {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateUserPermissionGQL extends Apollo.Mutation<CreateUserPermissionMutation, CreateUserPermissionMutationVariables> {
    override document = CreateUserPermissionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserPermissionsDocument = gql`
    query userPermissions {
  userRolePermissions {
    id
    description
    product
    scope
    target
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserPermissionsGQL extends Apollo.Query<UserPermissionsQuery, UserPermissionsQueryVariables> {
    override document = UserPermissionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateMyAccountDocument = gql`
    mutation updateMyAccount($input: UpdateMyAccountInput!) {
  updateMyAccount(input: $input) {
    account {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateMyAccountGQL extends Apollo.Mutation<UpdateMyAccountMutation, UpdateMyAccountMutationVariables> {
    override document = UpdateMyAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UsersDocument = gql`
    query users($where: UserFilterInput) {
  crmUsers(where: $where) {
    id
    email
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UsersGQL extends Apollo.Query<UsersQuery, UsersQueryVariables> {
    override document = UsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }