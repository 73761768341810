import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../auth/auth.service";
import {environment} from "../../../environments/environment";
import {MeQuery, User} from "../../../generated/graphql";
import {Router} from "@angular/router";

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

  me: any
  loading = true;
  env= environment
  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.authService.me().subscribe(({data, loading}) => {
      this.me = data.me as User;
      this.loading = loading;
    })
  }

  onLogOut(){
    this.authService.logout();
    localStorage.removeItem('token');
    this.router.navigate(['/auth/login'])
  }

}
