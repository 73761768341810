import {Component, Input, OnInit} from '@angular/core';
import {MenuItem} from "primeng/api";

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {

  @Input()
  title: String | undefined
  @Input()
  breadcrumbs: MenuItem[] = []
  @Input()
  count: String | undefined
  @Input()
  countTitle: String | undefined
  @Input()
  icon: String | undefined
  constructor() { }

  ngOnInit(): void {
  }

}
