
<div class="min-h-screen flex surface-ground">
  <app-sidebar></app-sidebar>
  <div class="min-h-screen flex flex-column relative flex-auto">
    <app-topbar></app-topbar>
    <div class="p-5 flex flex-column flex-auto">
      <div class="border-2 border-dashed surface-border border-round surface-section flex-auto">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
