<div class="flex justify-content-between align-items-center px-5 surface-0 border-bottom-1 surface-border relative lg:static" style="height:80px">
  <div class="flex">
<!--    <a pRipple class="cursor-pointer inline-flex align-items-center justify-content-center lg:hidden text-700 mr-3" pStyleClass="#app-sidebar-1" enterClass="hidden" enterActiveClass="fadeinleft" leaveToClass="hidden" leaveActiveClass="fadeoutleft">-->
<!--      <i class="pi pi-bars text-4xl"></i>-->
<!--    </a>-->

  </div>
  <a pRipple class="cursor-pointer block lg:hidden text-700" pStyleClass="@next" enterClass="hidden" enterActiveClass="fadein" leaveToClass="hidden" leaveActiveClass="fadeout">
    <i class="pi pi-ellipsis-v text-2xl"></i>
  </a>
  <ul class="list-none p-0 m-0 hidden lg:flex lg:align-items-center select-none lg:flex-row
                surface-section border-1 lg:border-none surface-border right-0 top-100 z-1 shadow-2 lg:shadow-none absolute lg:static">
    <li class="border-top-1 surface-border lg:border-top-none">
      <button pButton class="bg-white border-none" (click)="op.toggle($event)">
        <p-avatar *ngIf="!me.account?.logoUrl" shape="circle" [style]="{'background-color': '#817CCD', 'color': '#ffffff'}"
                  [label]="me.firstName.charAt(0) +  me.lastName.charAt(0)" size="xLarge"></p-avatar>
        <p-avatar *ngIf="me.account?.logoUrl" [image]="env.restUrl + me.account?.logoUrl" size="xLarge"></p-avatar>
        <span class="ml-2 text-700" *ngIf="!loading">{{me.firstName}} {{me.lastName}}</span>
        <i class="pi pi-angle-down font-size-20 mb-0 text-700"></i>
      </button>
      <p-overlayPanel #op>
        <ng-template pTemplate>
          <div class="px-3 flex flex-row justify-content-start align-items-center">
            <p-avatar *ngIf="!me.account?.logoUrl" shape="circle" [style]="{'background-color': '#817CCD', 'color': '#ffffff'}"
                      [label]="me.firstName.charAt(0) +  me.lastName.charAt(0)" size="xLarge"></p-avatar>
            <p-avatar *ngIf="me.account?.logoUrl" [image]="env.restUrl + me.account?.logoUrl" size="xLarge"></p-avatar>
            <div class="flex flex-column mt-2">
              <span class="ml-2 text-dark text-lg font-medium" *ngIf="!loading">{{me.firstName}} {{me.lastName}}</span>
              <span class="ml-2 text-gray-700 text-sm" *ngIf="!loading">{{me.email}}</span>
            </div>
          </div>
          <hr/>
          <ul class="list-none px-3">
            <li class="mt-2 mb-2 cursor-pointer" (click)="onLogOut()">
              <i class="pi pi-sign-out mb-0 text-lg p-color-gray-500"></i>
              <span class="text-lg ml-2">Logout</span>
            </li>
          </ul>
        </ng-template>
      </p-overlayPanel>
    </li>
  </ul>
</div>
