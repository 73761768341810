import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../auth/auth.service";
import {User} from "../../../generated/graphql";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  me: any
  loading = true;
  env= environment
  constructor(
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.authService.me().subscribe(({data, loading}) => {
      this.me = data.me as User;
      this.loading = loading;
    })
  }

}
