<div class="surface-section px-4 py-5 md:px-6 lg:px-8">
<!--  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">-->
<!--    <li>-->
<!--      <a class="text-500 no-underline line-height-3 cursor-pointer">Application</a>-->
<!--    </li>-->
<!--    <li class="px-2">-->
<!--      <i class="pi pi-angle-right text-500 line-height-3"></i>-->
<!--    </li>-->
<!--    <li>-->
<!--      <span class="text-900 line-height-3">Analytics</span>-->
<!--    </li>-->
<!--  </ul>-->
  <div class="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
    <div>
      <div class="font-medium text-3xl text-900">{{title}}</div>
      <div class="flex align-items-center text-700 flex-wrap">
        <div class="mr-5 flex align-items-center mt-3">
          <i [class]="icon"></i>
          <span class="ml-2">{{count}} {{countTitle}}</span>
        </div>
      </div>
    </div>
    <div class="mt-3 lg:mt-0">
      <ng-content select="button"></ng-content>
    </div>
  </div>
  <hr />
</div>
