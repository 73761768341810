import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopbarComponent } from './topbar/topbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import {RouterModule} from "@angular/router";
import {SharedModule} from "../shared/shared.module";



@NgModule({
    declarations: [
        TopbarComponent,
        SidebarComponent
    ],
  exports: [
    SidebarComponent,
    TopbarComponent,
  ],
    imports: [
        CommonModule,
        RouterModule,
        SharedModule
    ]
})
export class CoreModule { }
