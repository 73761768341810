import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthLayoutComponent} from "./layouts/auth-layout/auth-layout.component";
import {DefaultLayoutComponent} from "./layouts/default-layout/default-layout.component";
import {AuthGuard} from "./auth/auth.guard";

const AUTH_ROUTES: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
];
const DASHBOARD_ROUTES: Routes = [
  // {
  //   path: 'users',
  //   canActivate: [AuthGuard],
  //   loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
  // },
  {
    path: 'accounts',
    canActivate: [AuthGuard],
    loadChildren: () => import('./accounts/accounts.module').then(m => m.AccountsModule)
  },
  {
    path: 'permissions',
    canActivate: [AuthGuard],
    loadChildren: () => import('./permissions/permissions.module').then(m => m.PermissionsModule)
  },
];

const routes: Routes = [
  { path: '', redirectTo: 'accounts', pathMatch: 'full', },
  { path: '', component: AuthLayoutComponent, children: AUTH_ROUTES },
  { path: '', component: DefaultLayoutComponent, canActivate: [AuthGuard],  children: DASHBOARD_ROUTES }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
